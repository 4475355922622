import React, { useState } from 'react';
import { DataGrid, BtnBase, ResourceListSkeleton, ResourcesList } from '@aseel/common-ui';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router';
import { FUND_VALUATIONS } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Card, IconButton, Typography, Link } from '@mui/material';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ValuationsInfoDialog from './Components/ValuationsInfoDialog';
import CreateEditValuationsDialog from './Components/CreateEditValuationsDialog';
import { NavLink } from 'react-router-dom';

function ValuationsPage() {
    const { fundId } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedValuation, setSelectedValuation] = useState(null);
    const [page, setPage] = useState(0);
    const [showValuations, setshowValuations] = useState({ show: false, valuationsInfo: null });
    const { t } = useTranslation();

    const valuationsQuery = useQuery([FUND_VALUATIONS, { fundId, page }], () =>
        http.get(`/listings/${fundId}/valuations`).then((response) => response.data),
    );

    if (valuationsQuery.isLoading) {
        return <ResourceListSkeleton hasBreadcrumbs={true} />;
    }

    const handleCloseValuationDialog = () => {
        setshowValuations({
            ...showValuations,
            show: false,
        });
    };

    const handleShowValuationDialog = (row) => {
        setshowValuations({
            valuationsInfo: row,
            show: true,
        });
    };

    const handleOpenCreateDialog = () => {
        setIsDialogOpen(true);
        setIsEdit(false);
    };

    const handleOpenEditDialog = (row) => {
        setIsDialogOpen(true);
        setSelectedValuation(row);
        setIsEdit(true);
    };

    const valuationsColumns = [
        {
            field: 'date',
            headerName: t('funds.valuations.date'),
            editable: false,
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: 'value_fmt',
            headerName: t('funds.valuations.value'),
            editable: false,
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'actions',
            headerName: t('funds.reports.action'),
            editable: false,
            minWidth: 150,
            renderCell: (row) => (
                <div>
                    <IconButton onClick={() => handleShowValuationDialog(row.row)} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenEditDialog(row.row)} size="large">
                        <EditRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <>
            <CreateEditValuationsDialog
                isEdit={isEdit}
                isOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                fundId={fundId}
                selectedValuation={selectedValuation}
                valuationsQuery={valuationsQuery}
            />
            <ValuationsInfoDialog
                isOpen={showValuations.show}
                onClose={handleCloseValuationDialog}
                valuationsInfo={showValuations?.valuationsInfo}
            />
            <ResourcesList
                BeforeHeader={
                    <Breadcrumbs className="mb-4">
                        <Link component={NavLink} to="/app/funds">
                            {t('funds.funds')}
                        </Link>
                        <Typography color="textPrimary">#{fundId}</Typography>
                    </Breadcrumbs>
                }
                headerProps={{
                    title: t('funds.valuations.valuations'),
                    EndSide: (
                        <div className="flex">
                            <BtnBase fullWidth={false} onClick={handleOpenCreateDialog}>
                                {t('common.create')}
                            </BtnBase>
                        </div>
                    ),
                }}
                Section={
                    <Card className="mt-4">
                        <DataGrid
                            rows={valuationsQuery.data?.data || []}
                            columns={valuationsColumns}
                            page={page}
                            pageSize={valuationsQuery.data?.meta?.per_page}
                            rowCount={valuationsQuery.data?.meta?.total}
                            onPageChange={(newPage) => setPage(newPage)}
                            paginationMode="server"
                        />
                    </Card>
                }
            />
        </>
    );
}

export default ValuationsPage;
