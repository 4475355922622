import { ButtonBase, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

export default function FilesDisplayer({ files }) {
    const { t } = useTranslation();

    return files.length ? (
        <Card>
            <CardContent>
                <div>
                    <Typography variant="h6">{t('funds.files')}</Typography>
                    <div>
                        {files.map((file) => (
                            <div className="flex justify-between items-center w-full">
                                <Typography>{file.name}</Typography>
                                <ButtonBase
                                    component="a"
                                    href={file.url}
                                    target="_blank"
                                    className="items-center justify-start px-2 py-3">
                                    <GetAppRoundedIcon className="me-3 text-gray-600" />
                                </ButtonBase>
                            </div>
                        ))}
                    </div>
                </div>
            </CardContent>
        </Card>
    ) : null;
}
