import React from 'react';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { USERS } from 'Constants/QueriesKeys';
import CloseIcon from '@mui/icons-material/Close';

function ViewUserDialog({ isOpen, setIsOpen, userId, setSelectedUser }) {
    const { t } = useTranslation();
    
    const handleCloseUsersDialog = () => {
        setIsOpen(false);
        setSelectedUser(null);
    };

    const selectedUser = useQuery([USERS, { id: userId }], () =>
        http.get(`/users/${userId}`).then(({ data }) => data),
        {
            enabled: Boolean(userId),
        },
    );

    return (
        <Dialog open={isOpen} onClose={handleCloseUsersDialog} fullWidth>
            <DialogContent>
                <div className="grid grid-cols-6">
                    <div className="col-span-5 pb-2 pr-1">
                        <Typography color={'textSecondary'} variant="h6">{t('users.subtitle')}</Typography>
                    </div>
                    <div className="flex justify-end col-span-1 pb-2 pr-1">
                        <CloseIcon className="cursor-pointer" onClick={handleCloseUsersDialog} />
                    </div>
                </div>
                <div className="space-y-4 my-4">
                    <div className="grid gap-4 grid-cols-6">
                        <div className="col-span-3">
                            <div className="grid gap-4 grid-cols-6">
                                <div className="col-span-1">
                                    <Typography color={'textSecondary'} variant="body1">{t('users.full_name')}:</Typography>
                                </div>
                                <div className="col-span-4">
                                    <Typography variant="body1">{selectedUser?.data?.data?.first_name} {selectedUser?.data?.data?.last_name}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <div className="grid grid-cols-6">
                                <div className="col-span-3">
                                    <Typography color={'textSecondary'} variant="body1">{t('users.phone')}:</Typography>
                                </div>
                                <div className="col-span-3">
                                    <Typography variant="body1">{selectedUser?.data?.data?.fmt_phone} </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <div className="grid gap-4 grid-cols-6">
                                <div className="col-span-1">
                                    <Typography color={'textSecondary'} variant="body1">{t('users.email')}:</Typography>
                                </div>
                                <div className="col-span-4">
                                    <Typography variant="body1">{selectedUser?.data?.data?.email}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ViewUserDialog;
