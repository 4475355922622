import React from 'react';
import { Breadcrumbs, Card, IconButton, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { FUND_PAYOUTS } from 'Constants/QueriesKeys';
import { ResourceListSkeleton, ResourcesList, DataGrid } from '@aseel/common-ui';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CreatePayoutDialog from './Components/CreatePayoutDialog';
import RefreshIcon from '@mui/icons-material/Refresh';

function FundPayoutsPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const { fundId } = useParams();
    const payoutsQuery = useQuery([FUND_PAYOUTS, { fundId }], () =>
        http.get(`/listings/${fundId}/payouts`).then((response) => response.data),
    );

    const handlePayoutDetailsPage = (row) => {
        history.push(`/app/funds/${fundId}/payouts/${row.id}`);
    };

    const handleCreatePayout = () => {
        setIsOpen(true);
    };

    const handleCloseCreateDialog = () => {
        setIsOpen(false);
    };

    const payoutsColumns = [
        {
            field: 'id',
            headerName: t('funds.payouts.id'),
            flex: 0,
        },
        {
            field: 'payout_date',
            headerName: t('funds.payouts.date'),
            flex: 1,
        },
        {
            field: 'total',
            headerName: t('funds.payouts.total'),
            flex: 1,
        },
        {
            field: 'owners',
            headerName: t('funds.payouts.totalOwners'),
            flex: 1,
        },
        {
            field: 'paid_owners',
            headerName: t('funds.payouts.totalPaidOwners'),
            flex: 1,
        },
        {
            field: 'actions',
            headerName: t('funds.payouts.actions'),
            flex: 1,
            renderCell: (row) => (
                <div>
                    <IconButton onClick={() => handlePayoutDetailsPage(row.row)} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    if (payoutsQuery.isLoading) {
        return (
            <div>
                <ResourceListSkeleton hasBreadcrumbs={true} />
            </div>
        );
    }

    return (
        <div>
            <CreatePayoutDialog
                isOpen={isOpen}
                onClose={handleCloseCreateDialog}
                payoutsQuery={payoutsQuery}
                setIsOpen={setIsOpen}
            />
            <ResourcesList
                BeforeHeader={
                    <Breadcrumbs className="mb-4">
                        <Link component={NavLink} to={`/app/funds`}>
                            {t('funds.payouts.funds')}
                        </Link>
                        <Link component={NavLink} to={`/app/funds/${fundId}/payouts`}>
                            #{fundId}
                        </Link>
                        <Typography color="textPrimary"> {t('funds.payouts.payouts')}</Typography>
                    </Breadcrumbs>
                }
                headerProps={{
                    title: t('funds.payouts.payouts'),
                    EndSide: (
                        <div>
                            <IconButton onClick={payoutsQuery.refetch} size="large">
                                <RefreshIcon />
                            </IconButton>
                            <IconButton
                                fullWidth={false}
                                size="small"
                                className="rounded-full"
                                onClick={handleCreatePayout}>
                                <AddCircleRoundedIcon />
                            </IconButton>
                        </div>
                    ),
                }}
                Section={
                    <div className="mt-8">
                        <Card>
                            <DataGrid
                                columns={payoutsColumns}
                                rows={payoutsQuery.data?.data}
                                page={page}
                                pageSize={payoutsQuery.data?.meta.per_page}
                                rowCount={payoutsQuery.data?.meta.total}
                                onPageChange={(newPage) => setPage(newPage)}
                                paginationMode="server"
                                pagination
                            />
                        </Card>
                    </div>
                }
            />
        </div>
    );
}

export default FundPayoutsPage;
