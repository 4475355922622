import React, { useMemo, useState } from 'react';
import { BtnBase } from '@aseel/common-ui';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import TabPanel from './Components/TabPanel';
import { CacheProvider } from '@emotion/react';
import getForLocale from './Utils/GetForLocale';
import FundImages from './Components/FundImages';
import FundSummary from './Components/FundSummary';
import useTranslation from './Hooks/UseTranslation';
import TeamTab from './Components/FundTabs/TeamTab';
import RisksTab from './Components/FundTabs/RisksTab';
import DetailsTab from './Components/FundTabs/DetailsTab';
import { GetTabs } from './Components/Getters/TabsGetter';
import LocationTap from './Components/FundTabs/LocationTap';
import TimelineTab from './Components/FundTabs/TimelineTab';
import FinancialsTab from './Components/FundTabs/FinancialsTab';
import DueDiligenceTab from './Components/FundTabs/DueDiligenceTab';
import { createTheme, Divider, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { Container, IconButton, Paper, Tab, Tabs, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function FundPage({ fundData, onClose, authorizedEntityName, isEdit }) {
    const tabs = GetTabs();
    const { t, i18n } = useTranslation();

    const [currentTab, setCurrentTab] = useState(tabs.details.value);

    const handleLangChange = () => {
        i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');
    };

    const cacheRtl = useMemo(
        () =>
            createCache({
                key: 'muirtl',
                stylisPlugins: i18n.language === 'en' ? [] : [rtlPlugin],
            }),
        [i18n.language],
    );

    const theme = useMemo(() => {
        return responsiveFontSizes(
            createTheme({
                direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                palette: {
                    primary: {
                        main: '#132A46',
                    },
                    secondary: {
                        main: '#abc700',
                        contrastText: '#fff',
                    },
                    tertiary: {
                        main: '#FFE446',
                        contrastText: '#132A46',
                    },
                    green: {
                        main: '#4CAF50',
                        contrastText: '#fff',
                    },
                    gold: {
                        main: '#DCB807',
                        contrastText: '#fff',
                    },
                },
                spacing: (factor) => `${0.25 * factor}rem`,
            }),
        );
    }, [i18n.language]);

    const locale = i18n.language;

    return (
        <div dir={locale === 'en' ? 'ltr' : 'rtl'}>
            <ThemeProvider theme={theme}>
                <CacheProvider value={cacheRtl}>
                    <Container maxWidth="lg" className="py-8 lg:py-10">
                        <div className="flex items-center justify-between">
                            <Typography variant="h5">{t('funds.preview.previewTitle')}</Typography>
                            <div className="flex items-center space-s-3">
                                <div className="cursor-pointer" onClick={handleLangChange}>
                                    <Typography>{i18n.language === 'en' ? 'العربية' : 'English'}</Typography>
                                </div>
                                <IconButton onClick={onClose}>
                                    <CloseRoundedIcon />
                                </IconButton>
                            </div>
                        </div>
                        <Divider className="my-4" />
                        <div className="flex items-center justify-between mb-4">
                            <div>
                                <Typography color="primary" variant="h4" component="h1">
                                    {getForLocale(fundData?.listing, 'title', locale) || '---'}
                                </Typography>
                            </div>
                        </div>
                        <div className="mb-8 md:flex">
                            <div className="mb-4 md:mb-0 md:w-3/5 md:me-6">
                                <FundImages images={fundData.images} isEdit={isEdit} />
                            </div>

                            <div className="md:w-2/5 md:flex md:flex-col md:justify-between">
                                <div>
                                    <FundSummary fundData={fundData} authorizedEntityName={authorizedEntityName} />
                                    <BtnBase color="gold" className="mt-2">
                                        {t('funds.preview.investInFund', {
                                            fundName: getForLocale(fundData?.listing, 'title', locale) || '---',
                                        })}
                                    </BtnBase>
                                </div>
                            </div>
                        </div>

                        <Paper className="mb-3">
                            <Tabs
                                value={currentTab}
                                onChange={(event, value) => setCurrentTab(value)}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile>
                                {Object.values(tabs).map((tab) => (
                                    <Tab label={tab.label} value={tab.value} className="w-1/6" />
                                ))}
                            </Tabs>
                        </Paper>

                        <TabPanel value={tabs.details.value} currentTab={currentTab}>
                            <DetailsTab
                                text={getForLocale(fundData?.property_details, 'description', locale)}
                                files={fundData?.property_details?.files || []}
                            />
                        </TabPanel>
                        <TabPanel value={tabs.location.value} currentTab={currentTab}>
                            <LocationTap
                                text={getForLocale(fundData?.location, 'description', locale)}
                                mapUrl={fundData?.location?.gmap_url}
                                files={fundData?.location?.files || []}
                            />
                        </TabPanel>
                        <TabPanel value={tabs.risks.value} currentTab={currentTab}>
                            <RisksTab
                                text={getForLocale(fundData?.risks, 'content', locale)}
                                files={fundData?.risks?.files || []}
                            />
                        </TabPanel>

                        <TabPanel value={tabs.dueDiligence.value} currentTab={currentTab}>
                            <DueDiligenceTab
                                text={getForLocale(fundData?.due_diligence, 'content', locale)}
                                files={fundData?.due_diligence?.files || []}
                            />
                        </TabPanel>

                        <TabPanel value={tabs.financials.value} currentTab={currentTab}>
                            <FinancialsTab
                                text={getForLocale(fundData?.financial_details, 'content', locale)}
                                files={fundData?.financial_details?.files || []}
                            />
                        </TabPanel>

                        <TabPanel value={tabs.team.value} currentTab={currentTab}>
                            <TeamTab teams={fundData?.team} />
                        </TabPanel>

                        <TabPanel value={tabs.timeline.value} currentTab={currentTab}>
                            <TimelineTab updates={fundData?.timeline} />
                        </TabPanel>
                    </Container>
                </CacheProvider>
            </ThemeProvider>
        </div>
    );
}
