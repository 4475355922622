import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import useTranslation from '../Hooks/UseTranslation';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.main,
    },
}));

function DefinitionTermItem({ term, definition }) {
    return (
        <div className="py-2">
            <Typography variant="h4" color="primary">
                {definition}
            </Typography>

            <Typography variant="body1" color="textSecondary">
                {term}
            </Typography>
        </div>
    );
}

export default function FundSummary({ fundData, authorizedEntityName }) {
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <Typography className="mb-3 font-semibold" color="secondary" variant="h3" component="h2">
                    SAR {fundData.listing.target || '---'}
                </Typography>

                <Typography className="mb-2" color="textSecondary">
                    {t('funds.preview.raisedPercentage', { percentage: 0 })}
                </Typography>
                <BorderLinearProgress variant="determinate" value={0} />
            </div>

            <div className="mt-2 divide-y divide-gray-300">
                <DefinitionTermItem
                    definition={authorizedEntityName || '---'}
                    term={t('funds.preview.authorizedEntity')}
                />

                <DefinitionTermItem
                    definition={fundData.listing.total_shares || '---'}
                    term={t('funds.preview.totalUnits')}
                />

                <DefinitionTermItem
                    definition={t('funds.preview.unitPriceValue', {
                        value: fundData.listing.target / fundData.listing.total_shares || '---',
                    })}
                    term={t('funds.preview.unitPrice')}
                />

                <DefinitionTermItem
                    definition={t('funds.preview.sar', { minInvShare: fundData.listing.min_inv_share || '---' })}
                    term={t('funds.preview.minInvestmenAmount')}
                />
            </div>
        </div>
    );
}
