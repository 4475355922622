import React from 'react';
import { Skeleton } from '@mui/material';
import { Card, CardContent, Typography } from '@mui/material';
import { TableSkeleton, ResourcesList } from '@aseel/common-ui';

function PayoutDetailsSkeleton({ hasBreadcrumbs = false }) {
    return (
        <div>
            <div>
                {hasBreadcrumbs ? (
                    <Typography>
                        <Skeleton className="md:w-3/12 sm:w-5/12 w-4/12" />
                    </Typography>
                ) : null}

                <div className="mx-3 mt-5">
                    <Card>
                        <CardContent>
                            <div className="space-y-1.5">
                                <Typography variant="h5">
                                    <Skeleton className="md:w-4/12 sm:w-5/12 w-4/12" />
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    <Skeleton className="md:w-1/12 sm:w-5/12 w-4/12" />
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    <Skeleton className="md:w-2/12 sm:w-5/12 w-4/12" />
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    <Skeleton className="md:w-3/12 sm:w-5/12 w-4/12" />
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    <Skeleton className="md:w-2/12 sm:w-5/12 w-4/12" />
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    <Skeleton className="md:w-3/12 sm:w-5/12 w-4/12" />
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <div className="mt-10">
                    <ResourcesList
                        headerProps={{
                            EndSide: (
                                <Typography variant="h5">
                                    <Skeleton width={50} />
                                </Typography>
                            ),
                        }}
                    />
                    <div className="mt-3.5 mx-3">
                        <Card>
                            <CardContent>
                                <TableSkeleton />
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PayoutDetailsSkeleton;
