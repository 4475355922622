import { http } from 'Utils/Http/Http';
import useAuth from 'Hooks/UseAuth';
import { SignInComponent, SignInContainer } from '@aseel/common-components';

export default function SignInPage() {
    const { fetchUserAndEntityProfile } = useAuth();

    return (
        <SignInContainer
            signInRequest={(values) =>
                http
                    .post('/api/login', values, {
                        baseURL: process.env.REACT_APP_BASE_URL,
                    })
                    .then(() => {
                        return fetchUserAndEntityProfile();
                    })
            }>
            {({ handleSignIn, control, signInBtnName }) => (
                <SignInComponent
                    isSignUpShown
                    signUpPath="/sign-up"
                    onSignIn={handleSignIn}
                    control={control}
                    signInBtnName={signInBtnName}
                    imageUrl="https://images.unsplash.com/photo-1430417934865-589b63ad5c00?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1655&q=80"
                />
            )}
        </SignInContainer>
    );
}
