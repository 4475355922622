import React from 'react';
import SendResetLink from '@aseel/common-components/src/Auth/SendResetLink';
import SplitScreenWithImage from '@aseel/common-components/src/Layouts/SplitScreenWithImage';
import { http } from 'Utils/Http/Http';

const sendResetLink = (values) =>
    http.post('/api/forgot-password', values, {
        baseURL: process.env.REACT_APP_BASE_URL,
    });

export default function SendResetLinkPage() {
    return (
        <SplitScreenWithImage imgSrc="https://images.unsplash.com/photo-1430417934865-589b63ad5c00?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1655&q=80">
            <SendResetLink
                imageClassName="w-auto h-16 mb-5"
                titleClassName="mb-5"
                buttonClassName="mt-6"
                hasLogoAndTitle
                fullWidth
                sendResetLink={sendResetLink}
            />
        </SplitScreenWithImage>
    );
}
