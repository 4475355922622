import React from 'react';
import { Paper, Typography } from '@mui/material';

const styles = {
    cardIdentifier: {
        fontSize: '.875rem',
        fontWeight: '600',
        color: 'rgba(160, 174, 192, .9)',
    },
    card: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const OverviewCard = ({ data }) => {
    return (
        <Paper sx={styles.card}>
            <div>
                <Typography variant="h5">{data.value}</Typography>
                <Typography color="textSecondary" variant="body2">
                    {data.identifier}
                </Typography>
            </div>
            <div>{data.icon}</div>
        </Paper>
    );
};

export default OverviewCard;
