import i18n from 'Utils/I18n';

export const NavLinks = () => [
    {
        text: i18n.t('navLinks.login'),
        path: '/sign-in',
        type: 'route',
        isVisible: (auth) => !auth.user,
    },
    {
        text: i18n.t('navLinks.signUp'),
        path: '/sign-up',
        type: 'route',
        isVisible: (auth) => !auth.user,
    },
    {
        text: i18n.t('navLinks.dashboard'),
        path: '/app/dashboard',
        type: 'route',
        isVisible: (auth) => auth.user && auth.entity,
    },
];
