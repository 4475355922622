import React from 'react';
import { DASHBOARD_FUNDS } from '@aseel/constants/src/QueryKeys';
import { useQuery } from 'react-query';
import FundsCard from './FundCard';
import { useTranslation } from 'react-i18next';
import SectionLayout from '../SectionLayout';
import FundCardSkeleton from '../../Skeletons/Dashboard/FundCardSkeleton';
import { useHistory } from 'react-router';
import { DashedBoxEmpty } from '@aseel/common-ui';

const Funds = ({ fundRequest }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const fundsQuery = useQuery(DASHBOARD_FUNDS, fundRequest);

    return (
        <div>
            <SectionLayout
                sectionTitle={t('dashboard.funds')}
                isLoading={fundsQuery.isFetching}
                sectionContent={
                    fundsQuery.data?.length === 0 ? (
                        <div>
                            <DashedBoxEmpty
                                onClick={() => history.push('funds/create')}
                                title={t('funds.noFundsFound')}
                                hasButton={true}>
                                {t('funds.createFund')}
                            </DashedBoxEmpty>
                        </div>
                    ) : (
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {fundsQuery.isLoading
                                ? Array(8)
                                      .fill(null)
                                      .map(() => {
                                          return <FundCardSkeleton />;
                                      })
                                : fundsQuery.data?.map((data) => {
                                      return <FundsCard data={data} />;
                                  })}
                        </div>
                    )
                }
            />
        </div>
    );
};

export default Funds;
