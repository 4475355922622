import React from 'react';
import { Card, CardContent, Chip, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const ListItem = ({ label, value }) => {
    return (
        <div className="flex justify-between py-3">
            <Typography variant="body1" color="textSecondary">
                {label}
            </Typography>
            <Typography variant="body1" className="font-medium">
                {value}
            </Typography>
        </div>
    );
};

const FundsCard = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <div
                style={{ backgroundImage: `url(${data.image})` }}
                className="h-72 bg-cover bg-no-repeat relative flex items-end">
                <div className="bg-black bg-opacity-25 h-full w-full absolute z-10"></div>
                <Typography className="relative z-20 pb-2 ps-2 text-white" variant="h5">
                    {data.title}
                </Typography>
            </div>
            <CardContent>
                <Chip
                    size="small"
                    label={data.enabled ? t('dashboard.public') : t('dashboard.notPublic')}
                    className={clsx([data.enabled ? 'bg-green-100 text-green-600' : null, 'mb-2'])}
                />

                <div className="divide-y divide-gray-300">
                    <ListItem label={t('dashboard.raised')} value={`SAR ${data.target}`} />
                    <ListItem label={t('dashboard.target')} value={`SAR ${data.raised}`} />
                    <ListItem label={t('dashboard.deadline')} value={data.deadline} />
                    <ListItem label={t('dashboard.fundType')} value={data.type} />
                    {data.authorized_entity === undefined ? null : (
                        <ListItem
                            label={t('dashboard.authorizedEntity')}
                            value={data.authorized_entity === null ? '---' : data.authorized_entity}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default FundsCard;
