import React from 'react';
import { Alert } from '@mui/material';
import FilesDisplayer from '../FilesDisplayer';
import { useTranslation } from 'react-i18next';

export default function ProofDocuments({ files }) {
    const { t } = useTranslation();

    return (
        <div className="space-y-2.5">
            <Alert severity="info">{t('funds.theseDocumentsWillBePrivateOnlyToAseelModerators')}</Alert>
            <FilesDisplayer files={files} />
        </div>
    );
}
