import React from 'react';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Avatar, Card, CardContent, Divider, Typography } from '@mui/material';
import getForLocale from '../../Utils/GetForLocale';
import useTranslation from '../../Hooks/UseTranslation';

export default function TeamTab({ teams }) {
    const { i18n } = useTranslation();

    return teams === undefined ? null : (
        <Card>
            <CardContent>
                <div className="space-y-4">
                    {teams.map((team) => (
                        <div>
                            <Typography variant="h6">{getForLocale(team, 'name', i18n.language)}</Typography>
                            <Divider className="mb-3" />

                            <ul className="grid gap-4 lg:grid-cols-4">
                                {team.members?.map((member) => (
                                    <li className="flex items-center">
                                        <Avatar className="me-2">
                                            <PersonRoundedIcon />
                                        </Avatar>
                                        <div>
                                            <Typography variant="body1" component="li">
                                                {member.name}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className="text-xs"
                                                component="li"
                                                color="textSecondary">
                                                {getForLocale(member, 'job_title', i18n.language)}
                                            </Typography>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    );
}
