import React from 'react';
import { TableSkeleton } from '@aseel/common-ui';
import { Card, Divider, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

export default function FundIBanAndInvestorsSkeletons() {
    return (
        <div className="space-y-5 px-1">
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        lg: `repeat(3, 1fr)`,
                    },
                    gap: {
                        xs: 1,
                        sm: 5,
                    },
                }}>
                {Array(3)
                    .fill(0)
                    .map(() => (
                        <Card className="mt-5">
                            <div className="py-5">
                                <div className="flex justify-center">
                                    <Skeleton className="w-1/4" />
                                </div>
                                <div className="flex justify-center">
                                    <Skeleton className="w-1/2" />
                                </div>
                            </div>
                            <Divider />
                            <div className="py-5 text-center">
                                <div className="flex justify-center">
                                    <Skeleton className="w-1/4" />
                                </div>
                                <div className="flex justify-center">
                                    <Skeleton className="w-1/2" />
                                </div>
                            </div>
                        </Card>
                    ))}
            </Box>
            <div className="mt-5">
                <Skeleton className="md:w-2/12 w-1/4" />
                <Card>
                    <TableSkeleton />
                </Card>
            </div>
        </div>
    );
}
