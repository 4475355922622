import { Yup } from '@aseel/common-utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { handleError } from '@aseel/http';
import { http } from 'Utils/Http/Http';
import { Card, CardContent, CardHeader, Container, Divider } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import Btn from '@aseel/common-ui/src/Components/Buttons/Btn';
import { FileUploader, setProgress, InputController } from '@aseel/common-ui';
import useAuth from 'Hooks/UseAuth';
import { useHistory } from 'react-router-dom';

const UPDATE_BTN = 'UPDATE_BTN';

const schema = Yup.object().shape({
    description: Yup.string().required(),
    cr_number: Yup.string().required(),
    name: Yup.string().required(),
});

export default function UpdateEntityPage() {
    const { t } = useTranslation();
    const [cr, setCr] = useState([]);
    const [cmaLicense, setCmaLicense] = useState([]);
    const [crErrors, setCrErrors] = useState({});
    const [cmaErrors, setCmaErrors] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const { fetchEntityProfile, entity } = useAuth();
    const history = useHistory();

    const { control, handleSubmit, setError, reset } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        reset({
            name: entity.name,
            cr_number: entity.cr_number,
            description: entity.description,
        });
        setCr([...entity.cr]);
        setCmaLicense([...entity.cma_license]);
    }, []);

    const handleFormSubmission = async (values) => {
        setProgress(UPDATE_BTN, true);

        try {
            const form = new FormData();
            Object.keys(values).forEach((key) => {
                form.append(key, values[key]);
            });

            cr.forEach((value) => {
                if (value.file instanceof File) {
                    form.append(`cr[${value.id}]`, value.file);
                }
            });

            cmaLicense.forEach((value) => {
                if (value.file instanceof File) {
                    form.append(`cma_license`, value.file);
                }
            });

            form.append('_method', 'put');

            await http.post('/entity/profile', form);
            await fetchEntityProfile();
            history.push('/entity/profile');
        } catch (error) {
            handleError(error, { setError });
        }

        setProgress(UPDATE_BTN, false);
    };

    return (
        <div className="flex-grow flex flex-col justify-center items-center py-16">
            <Container maxWidth="sm">
                <Card>
                    <CardHeader title={t('entity.updateProfile')} />
                    <Divider className="mb-2" />
                    <CardContent>
                        <form onSubmit={handleSubmit(handleFormSubmission)}>
                            <div className="space-y-5">
                                <div className="space-y-5">
                                    <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                                        <InputController
                                            control={control}
                                            name="name"
                                            label={t('register.companyName')}
                                        />
                                        <InputController
                                            control={control}
                                            name="cr_number"
                                            label={t('register.crNumber')}
                                        />
                                    </div>
                                    <InputController
                                        multiline
                                        rows="4"
                                        control={control}
                                        name="description"
                                        label={t('register.description')}
                                    />
                                    <FileUploader
                                        errors={crErrors}
                                        name="cr"
                                        files={cr}
                                        onChange={(f) => setCr(f)}
                                        label={t('register.cr')}
                                        variant="input"
                                        disabled={isUploading}
                                    />
                                    <FileUploader
                                        errors={cmaErrors}
                                        name="cma_license"
                                        files={cmaLicense}
                                        onChange={(f) => setCmaLicense(f)}
                                        label={t('register.cmaLicense')}
                                        variant="input"
                                        disabled={isUploading}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end mt-5">
                                <Btn type="submit" name={UPDATE_BTN} fullWidth={false}>
                                    {t('common.update')}
                                </Btn>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}
