import { useState } from 'react';
import { unset } from 'lodash';
import TabsPanel from './TabsPanel';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { Yup } from '@aseel/common-utils';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { Scope } from '@aseel/constants';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { NavLink, useHistory } from 'react-router-dom';
import { RootPropsContext } from './Utils/RootPropsContext';
import FundBasicDetailInputs from './Form/FundBasicDetailInputs';
import FundPreviewDialog from '../PreviewFund/FundPreviewDialog';
import { AUTHORIZED_ENTITIES, FUNDS } from '@aseel/constants/src/QueryKeys';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { Btn, ResourcesList, setProgress, UploaderController } from '@aseel/common-ui';
import { Breadcrumbs, Card, CardContent, IconButton, Link, Typography } from '@mui/material';

const DECIMAL_REGEX = /^(?=.)(([0-9]*)(\.([0-9]+))?)$/;

const schema = (scope) => {
    let listingItem = {
        is_closed: Yup.boolean().required(),
        is_visible: Yup.boolean().required(),
        title_en: Yup.string().required(),
        title_ar: Yup.string().required(),
        deadline: Yup.mixed().required(),
        city_id: Yup.string().required().nullable(),
        type_id: Yup.string().required().nullable(),
        investment_type: Yup.string().required().nullable(),
        total_shares: Yup.number().typeError('nullable').integer().required(),
        min_inv_share: Yup.number().typeError('nullable').integer().required(),
        max_inv_share: Yup.number().typeError('nullable').integer().required(),
        reserved_shares: Yup.number().typeError('nullable').integer().required(),
        target: Yup.string().required().matches(DECIMAL_REGEX, 'mustBeANumber'),
        gross_yield: Yup.string().required().matches(DECIMAL_REGEX, 'mustBeANumber'),
        dividend_yield: Yup.string().required().matches(DECIMAL_REGEX, 'mustBeANumber'),
        rent_amount: Yup.string().required().matches(DECIMAL_REGEX, 'mustBeANumber'),
        authorized_entity_id: Yup.string().required().nullable(),
        bank_account_id: Yup.string().nullable(),
    };

    if (scope === Scope.authorizedEntity) {
        unset(listingItem, ['authorized_entity_id']);
    }

    return Yup.object().shape({
        listing: Yup.object().shape(listingItem),

        images: Yup.array(),

        due_diligence: Yup.object().shape({
            content_en: Yup.string().required(),
            content_ar: Yup.string().required(),
            files: Yup.array(),
        }),

        property_details: Yup.object().shape({
            description_en: Yup.string().required(),
            description_ar: Yup.string().required(),
            files: Yup.array(),
        }),

        location: Yup.object().shape({
            description_en: Yup.string().required(),
            description_ar: Yup.string().required(),
            gmap_url: Yup.string().required(),
            files: Yup.array(),
        }),

        financial_details: Yup.object().shape({
            content_en: Yup.string().required(),
            content_ar: Yup.string().required(),
            files: Yup.array(),
        }),

        risks: Yup.object().shape({
            content_en: Yup.string().required(),
            content_ar: Yup.string().required(),
            files: Yup.array(),
        }),

        proof_docs: Yup.object().shape({
            files: Yup.array(),
        }),

        team: Yup.array().of(
            Yup.object().shape({
                name_en: Yup.string().required(),
                name_ar: Yup.string().required(),
                members: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required(),
                        job_title_en: Yup.string().required(),
                        job_title_ar: Yup.string().required(),
                    }),
                ),
            }),
        ),

        timeline: Yup.array().of(
            Yup.object().shape({
                title_en: Yup.string().required(),
                title_ar: Yup.string().required(),
                date: Yup.date().required().nullable(),
                description_en: Yup.string().required(),
                description_ar: Yup.string().required(),
            }),
        ),

        terms_conditions: Yup.object().shape({
            description_en: Yup.string().required(),
            description_ar: Yup.string().required(),
        }),
    });
};

export const tabs = {
    due_diligence: 'DUE_DILIGENCE',
    financial_details: 'FINANCIAL_DETAILS',
    property_details: 'DETAILS',
    location: 'LOCATION',
    risks: 'RISKS',
    team: 'TEAM',
    timeline: 'TIMELINE',
    proof_docs: 'PROOF_DOCS',
    terms_conditions: 'TERMS_CONDITIONS',
};

const FUND_SAVE_BUTTON = 'FUND_SAVE_BUTTON';

export default function FundCreateEditForm({
    fundId,
    scope,
    getFundRequest,
    uploadImagesRequest,
    uploadFilesRequest,
    infoBasedOnScope,
}) {
    const isEdit = fundId !== 'create';
    const history = useHistory();
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState(false);
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

    const { control, handleSubmit, setError, reset, getValues, watch } = useForm({
        defaultValues: {
            listing: {
                is_visible: false,
                is_closed: false,
                gross_yield: 0,
                dividend_yield: 0,
                rent_amount: 0,
                reserved_shares: 0,
            },
            images: [],
            files: [],
        },
        resolver: (values, context, options) => {
            return yupResolver(schema(context.scope))(values, context, options);
        },
        context: { scope },
    });

    const fundQuery = useQuery([FUNDS, { id: fundId }], getFundRequest, {
        enabled: isEdit,
        onSuccess: (data) => {
            reset({ ...data });
        },
    });

    const handleClosePreviewDialog = () => {
        setIsPreviewDialogOpen(!isPreviewDialogOpen);
    };

    const uploadImages = (returnedFundId, images) => {
        if (isEdit) {
            returnedFundId = fundId;
        }

        const formData = new FormData();

        Object.keys(images).forEach((key) => {
            images.forEach((el) => {
                if (el.file instanceof File) {
                    formData.append(`files[${el.id}]`, el.file);
                }
            });
        });

        return uploadImagesRequest({ returnedFundId, formData });
    };

    const uploadFiles = (returnedFundId, tabsFiles) => {
        if (isEdit) {
            returnedFundId = fundId;
        }

        const formData = new FormData();

        Object.keys(tabsFiles).forEach((key) => {
            tabsFiles[key].forEach((el) => {
                if (el.file instanceof File) {
                    formData.append(`attachments[${el.id}][file]`, el.file);
                    formData.append(`attachments[${el.id}][type]`, tabs[key]);
                }
            });
        });

        return uploadFilesRequest({ returnedFundId, formData });
    };

    const handleFormSubmission = async (formData) => {
        if (typeof formData.listing.deadline === 'object') {
            formData.listing.deadline = formData.listing.deadline.format('YYYY-MM-DD');
        }

        const tabsFiles = {};
        tabsFiles.due_diligence = formData.due_diligence.files;
        tabsFiles.property_details = formData.property_details.files;
        tabsFiles.location = formData.location.files;
        tabsFiles.financial_details = formData.financial_details.files;
        tabsFiles.risks = formData.risks.files;
        tabsFiles.proof_docs = formData?.proof_docs?.files;

        const images = [...formData.images];

        setProgress(FUND_SAVE_BUTTON, true);
        setIsUploading(true);
        try {
            const response = await http.request({
                method: isEdit ? 'PUT' : 'POST',
                url: isEdit ? `/listings/${fundId}` : '/listings',
                data: formData,
            });

            const returnedFundId = response.data.id;

            await uploadImages(returnedFundId, images);
            await uploadFiles(returnedFundId, tabsFiles);
            history.push('/app/funds');
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(FUND_SAVE_BUTTON, false);
        setIsUploading(false);
    };

    const watchForm = watch();

    const authorizedEntitiesQuery = useQuery(
        [AUTHORIZED_ENTITIES, { type: 'ApprovedWithBankAccounts' }],
        () => http.get(`/authorized-entities/approved-with-bank-accounts`).then((data) => data.data.data),
        {
            enabled: scope === Scope.admin,
        },
    );

    const selectedAuthorizedEntityId = watch('listing.authorized_entity_id', null);

    const selectedAuthorizedEntity = authorizedEntitiesQuery.data?.find((el) => el.id === selectedAuthorizedEntityId);

    let authorizedEntityName;

    if (scope === Scope.admin) {
        authorizedEntityName = selectedAuthorizedEntity?.name;
    } else {
        authorizedEntityName = infoBasedOnScope.entityName;
    }

    const rootProps = useMemo(
        () => ({
            fundId,
            isEdit,
            control,
        }),
        [fundId, isEdit, control],
    );

    return (
        <RootPropsContext.Provider value={rootProps}>
            <div>
                <form onSubmit={handleSubmit(handleFormSubmission)}>
                    <ResourcesList
                        BeforeHeader={
                            <Breadcrumbs className="mb-4">
                                <Link component={NavLink} to="/app/funds">
                                    {t('funds.funds')}
                                </Link>
                                {isEdit ? (
                                    <Typography color="textPrimary">
                                        #{fundId} {t('funds.editFund')}
                                    </Typography>
                                ) : (
                                    <Typography color="textPrimary">{t('funds.createFund')}</Typography>
                                )}
                            </Breadcrumbs>
                        }
                        headerProps={{
                            title: isEdit ? t('funds.editFund') : t('funds.createFund'),
                            EndSide: (
                                <div className="flex items-center space-x-2.5">
                                    <Tooltip title={t('funds.previewFund')} placement="top">
                                        <IconButton onClick={() => setIsPreviewDialogOpen(!isPreviewDialogOpen)}>
                                            <RemoveRedEyeRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Btn name={FUND_SAVE_BUTTON} type="submit">
                                        {isEdit ? t('funds.save') : t('common.create')}
                                    </Btn>
                                </div>
                            ),
                        }}
                        Section={
                            <div className="mt-4 space-y-4">
                                <Card>
                                    <CardContent>
                                        <FundBasicDetailInputs
                                            scope={scope}
                                            isEdit={isEdit}
                                            control={control}
                                            authorizedEntitiesQuery={authorizedEntitiesQuery}
                                            isInvestingStarted={fundQuery.data?.investing_started}
                                        />
                                    </CardContent>
                                </Card>

                                <Card>
                                    <CardContent>
                                        <div className="mb-2">
                                            <Typography variant="h6" component="h2">
                                                {t('funds.images')}
                                            </Typography>
                                            <Typography variant="body2">
                                                {t('funds.hereYouCanUploadImagesAboutTheFundToAttractTheInvestors')}
                                            </Typography>
                                        </div>
                                        <UploaderController
                                            control={control}
                                            name="images"
                                            label={t('funds.selectImages')}
                                            disabled={isUploading}
                                        />
                                    </CardContent>
                                </Card>
                                <TabsPanel control={control} getValues={getValues} />
                            </div>
                        }
                    />
                </form>
                <FundPreviewDialog
                    isEdit={isEdit}
                    fundData={watchForm}
                    isOpen={isPreviewDialogOpen}
                    onClose={handleClosePreviewDialog}
                    authorizedEntityName={authorizedEntityName}
                />
            </div>
        </RootPropsContext.Provider>
    );
}
