import React from 'react';
import { useTranslation } from 'react-i18next';
import { WysiwygViewer } from '@aseel/common-components';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { ButtonBase, Card, Divider, Link, Typography } from '@mui/material';

export default function Location({ text, files, mapUrl }) {
    const { t } = useTranslation();

    return (
        <Card>
            {mapUrl ? (
                <>
                    <div className="p-4">
                        <Link
                            component="a"
                            href={mapUrl}
                            fullWidth={false}
                            variant="outline"
                            className="flex space-s-1.5 no-underline"
                            style={{ textDecoration: 'none' }}>
                            <LocationOnRoundedIcon />
                            <Typography>{t('funds.tabs.location')}</Typography>
                        </Link>
                    </div>
                </>
            ) : null}
            {files.length > 0 && mapUrl ? <Divider /> : null}
            {files?.length > 0 ? (
                <div className="grid gap-4 lg:grid-cols-3">
                    {files.map((file) => (
                        <div>
                            <ButtonBase
                                component="a"
                                href={file.url}
                                target="_blank"
                                className="items-center justify-start px-2 py-3">
                                <GetAppRoundedIcon className="me-3" />
                                {file.name}
                            </ButtonBase>
                        </div>
                    ))}
                </div>
            ) : null}
            {files.length > 0 && text ? <Divider /> : null}
            {text ? (
                <div className="p-4">
                    <WysiwygViewer text={text} />
                </div>
            ) : null}
        </Card>
    );
}
