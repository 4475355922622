import React from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UploaderController } from '@aseel/common-ui';
import { useRootProps } from '../Utils/RootPropsContext';

export default function ProofDocuments() {
    const { t } = useTranslation();
    const { control } = useRootProps();

    return (
        <div className="py-3.5 space-y-2.5">
            <Alert severity="info">{t('funds.theseDocumentsWillBePrivateOnlyToAseelModerators')}</Alert>
            <UploaderController control={control} name="proof_docs.files" label={t('funds.uploadFiles')} />
        </div>
    );
}
