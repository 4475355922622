import React from 'react';
import { Card } from '@mui/material';
import { Box } from '@mui/system';

export default function CardWithPattern({ children, className, patternSx }) {
    return (
        <div className={className}>
            <section className="relative" aria-labelledby="contact-heading">
                <div className="absolute w-full h-1/2 bg-warm-gray-50" aria-hidden="true" />
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Box
                        className="absolute z-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                        sx={{
                            top: {
                                xs: 0,
                                md: 5,
                                lg: 200,
                            },
                            right: {
                                xs: 210,
                                md: 110,
                                lg: 110,
                                xl: 50,
                            },
                            ...patternSx,
                        }}
                        width={404}
                        height={384}
                        component="svg"
                        fill="none"
                        viewBox="0 0 404 384"
                        aria-hidden="true">
                        <defs>
                            <pattern
                                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse">
                                <rect x={0} y={0} width={4} height={4} className="text-gray-300" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                    </Box>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Card className="relative bg-white">
                        {children}
                    </Card>
                </div>
            </section>
        </div>
    );
}
