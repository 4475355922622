import { Box } from '@mui/system';
import React, { useState } from 'react';
import TeamTab from './FundTabs/TeamTab';
import { i18n } from '@aseel/common-utils';
import { Empty } from '@aseel/common-ui';
import { useParams } from 'react-router';
import RisksTab from './FundTabs/RisksTab';
import { useTranslation } from 'react-i18next';
import TabPanel from '../Components/TabPanel';
import DetailsTab from './FundTabs/DetailsTab';
import TimelineTab from './FundTabs/TimelineTab'
import LocationTab from './FundTabs/LocationTab';
import FinancialsTab from './FundTabs/FinancialsTab';
import FundActions from '../Components/FundActions';
import TitleWithText from '../Components/TitleWithText';
import ProofDocuments from './FundTabs/ProofDocuments';
import DueDiligenceTab from './FundTabs/DueDiligenceTab'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { Card, CardContent, Typography, Chip } from '@mui/material';
import ApprovalStatusChip from '../../StatusChip/ApprovalStatusChip';
import RoundedTabs from '@aseel/common-ui/src/Components/Tabs/RoundedTabs';
import { approvalStatusModalTypes } from '../../StatusChip/ApprovalStatusChip';
import TermsAndConditions from '../FundInformation/FundTabs/TermsAndConditions';

export const tabsValue = {
    details: 1,
    dueDiligence: 2,
    risks: 3,
    financials: 4,
    team: 5,
    timeline: 6,
    location: 7,
    proofDocuments: 8,
    termsAndConditions: 9,
};

export const tabs = () => [
    {
        value: tabsValue.details,
        label: i18n.t('funds.tabs.details'),
    },
    {
        value: tabsValue.dueDiligence,
        label: i18n.t('funds.tabs.dueDiligence'),
    },
    {
        value: tabsValue.risks,
        label: i18n.t('funds.tabs.risks'),
    },
    {
        value: tabsValue.financials,
        label: i18n.t('funds.tabs.financials'),
    },
    {
        value: tabsValue.team,
        label: i18n.t('funds.tabs.team'),
    },
    {
        value: tabsValue.timeline,
        label: i18n.t('funds.tabs.timeline'),
    },
    {
        value: tabsValue.location,
        label: i18n.t('funds.tabs.location'),
    },
    {
        value: tabsValue.proofDocuments,
        label: i18n.t('funds.tabs.proofDocuments'),
    },
    {
        value: tabsValue.termsAndConditions,
        label: i18n.t('funds.tabs.termsAndConditions'),
    },
];

function FundInformation({
    data,
    informationContainerClassName,
    statusesContainerClassName,
    areActionBtnsVisible,
    sx,
    isAuthorizedEntityVisible = true,
}) {
    const { t } = useTranslation();
    const { fundId } = useParams();
    const [currentTab, setCurrentTab] = useState(tabsValue.details);

    return (
        <Box className="space-y-5" sx={{ px: 1.5, ...sx }}>
            <Card>
                <CardContent className="pb-2">
                    <div className="p-5">
                        <div className="space-y-3">
                            <div>
                                <Typography variant="h6">{data?.title_en}</Typography>
                                <Typography variant="h6">{data?.title_ar}</Typography>
                            </div>
                            <div className={statusesContainerClassName}>
                                <Chip
                                    size="small"
                                    label={data?.is_visible ? t('funds.visible') : t('funds.notVisible')}
                                />
                                <Chip size="small" label={data?.is_closed ? t('funds.closed') : t('funds.notClosed')} />
                                <ApprovalStatusChip
                                    status={data?.review_status?.value}
                                    label={data?.review_status?.description}
                                    modalType={approvalStatusModalTypes.general}
                                />
                            </div>
                            <div>
                                <FundActions
                                    fundId={fundId}
                                    size="small"
                                    iconSize={18}
                                    isVisible={areActionBtnsVisible}
                                />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card>
                <div className={informationContainerClassName}>
                    <TitleWithText title={t('funds.city_id')} text={data.city?.name} />
                    <TitleWithText title={t('funds.type_id')} text={data.type?.name} />
                    <TitleWithText title={t('funds.target')} text={data?.target} />
                    <TitleWithText title={t('funds.total_shares')} text={data?.total_shares} />
                    <TitleWithText title={t('funds.min_inv_share')} text={data?.min_inv_share} />
                    <TitleWithText title={t('funds.max_inv_share')} text={data?.max_inv_share} />
                    <TitleWithText title={t('funds.reserved_shares')} text={data?.reserved_shares} />
                    <TitleWithText title={t('funds.deadline')} text={data?.deadline} />
                    <TitleWithText title={t('funds.gross_yield')} text={data?.gross_yield} />
                    <TitleWithText title={t('funds.dividend_yield')} text={data?.dividend_yield} />
                    <TitleWithText title={t('funds.rent_amount')} text={data?.rent_amount} />
                    {isAuthorizedEntityVisible ? (
                        <TitleWithText title={t('funds.authorizedEntity')} text={data?.authorized_entity} />
                    ) : null}
                    <TitleWithText title={t('funds.bankAccount')} text={data?.bank_account} />
                </div>
            </Card>
            <Card>
                <div className="p-10">
                    <Typography variant="h6">{t('funds.images')}</Typography>
                    <div className="mt-5 space-y-3">
                        {data.images.length ? (
                            data.images.map((image) => (
                                <div className="flex justify-between">
                                    <div>{image.name}</div>
                                    <a href={image.url} target="_blank">
                                        <GetAppRoundedIcon className="text-gray-600" />
                                    </a>
                                </div>
                            ))
                        ) : (
                            <Empty>{t('funds.thereIsNoImages')}</Empty>
                        )}
                    </div>
                </div>
            </Card>
            <RoundedTabs
                value={currentTab}
                tabs={tabs()}
                centered={false}
                variant="scrollable"
                onChange={(event, value) => setCurrentTab(value)}
            />
            <TabPanel value={tabsValue.dueDiligence} currentTab={currentTab}>
                <DueDiligenceTab
                    text={{
                        en: data?.due_diligence?.content_en,
                        ar: data?.due_diligence?.content_ar,
                    }}
                    files={data?.due_diligence?.files || []}
                />
            </TabPanel>
            <TabPanel value={tabsValue.details} currentTab={currentTab}>
                <DetailsTab
                    text={{
                        en: data?.property_details?.description_en,
                        ar: data?.property_details?.description_ar,
                    }}
                    files={data?.property_details?.files || []}
                />
            </TabPanel>
            <TabPanel value={tabsValue.location} currentTab={currentTab}>
                <LocationTab
                    text={{
                        en: data?.location?.description_en,
                        ar: data?.location?.description_ar,
                    }}
                    mapUrl={data?.location?.gmap_url}
                    files={data?.location?.files || []}
                />
            </TabPanel>
            <TabPanel value={tabsValue.risks} currentTab={currentTab}>
                <RisksTab
                    text={{
                        en: data?.risks?.content_ar,
                        ar: data?.risks?.content_ar,
                    }}
                    files={data?.location?.files || []}
                />
            </TabPanel>
            <TabPanel value={tabsValue.financials} currentTab={currentTab}>
                <FinancialsTab
                    text={{
                        en: data?.financial_details?.content_en,
                        ar: data?.financial_details?.content_ar,
                    }}
                    files={data?.financial_details?.files || []}
                />
            </TabPanel>
            <TabPanel value={tabsValue.team} currentTab={currentTab}>
                <TeamTab teams={data?.team} />
            </TabPanel>
            <TabPanel value={tabsValue.timeline} currentTab={currentTab}>
                <TimelineTab updates={data?.timeline} />
            </TabPanel>
            <TabPanel value={tabsValue.proofDocuments} currentTab={currentTab}>
                <ProofDocuments files={data?.proof_docs?.files || []} />
            </TabPanel>
            <TabPanel value={tabsValue.termsAndConditions} currentTab={currentTab}>
                <TermsAndConditions
                    text={{
                        en: data?.terms_conditions?.description_en,
                        ar: data?.terms_conditions?.description_ar,
                    }}
                />
            </TabPanel>
        </Box>
    );
}

export default FundInformation;
