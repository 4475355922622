import { Card, CardContent, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';

export default function OverviewSkeleton({ count }) {
    return (
        <div>
            <div className="grid lg:grid-cols-3 gap-4">
                {Array(count)
                    .fill(null)
                    .map(() => (
                        <div>
                            <Card>
                                <CardContent>
                                    <div className="flex items-center">
                                        <div className="flex-grow">
                                            <Typography variant="h5">
                                                <Skeleton className="w-1/2" />
                                            </Typography>
                                            <Typography>
                                                <Skeleton className="w-1/4" />
                                            </Typography>
                                        </div>
                                        <div>
                                            <Skeleton width={40} variant="rectangular" height={40} />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))}
            </div>
        </div>
    );
}
