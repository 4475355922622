import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-material-ui-carousel';

export default function FundImages({ images }) {
    const [activeStep, setActiveStep] = useState(0);
    const objectUrls = useRef([]);

    useEffect(() => {
        const objectUrlsCopy = objectUrls.current;

        return () => {
            objectUrlsCopy.forEach((objectUrl) => {
                window.URL.revokeObjectURL(objectUrl);
            });
        };
    });
    
    return (
        <div>
            <Carousel
                autoPlay={false}
                indicators={false}
                index={activeStep}
                next={(next) => setActiveStep(next)}
                prev={(prev) => setActiveStep(prev)}
                navButtonsAlwaysVisible>
                {images?.map((item) => {
                    if (item.file instanceof File) {
                        const objectUrl = URL.createObjectURL(item.file);
                        objectUrls.current.push(objectUrl);
                        return <Image key={item.id} url={window.URL.createObjectURL(item.file)} />;
                    } else {
                        return <Image key={item.id} url={item.url} />;
                    }
                })}
            </Carousel>
        </div>
    );
}

function Image({ url }) {
    return (
        <div
            className="bg-center bg-no-repeat bg-cover"
            style={{
                paddingTop: '100%',
                backgroundImage: `url(${url})`,
            }}></div>
    );
}
