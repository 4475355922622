import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleError } from '@aseel/http';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import {
    InputController,
    Btn,
    setProgress,
    FormSkeleton,
    EditorController,
    UploaderController,
} from '@aseel/common-ui';
import { useQuery } from 'react-query';
import { FUND_REPORTS } from 'Constants/QueriesKeys';
import { Breadcrumbs, Card, CardContent, Divider, Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const CREATE_EDIT_BTN = 'CREATE_EDIT_BTN';

const schema = Yup.object().shape({
    title_en: Yup.string().required(),
    title_ar: Yup.string().required(),
    description_en: Yup.string().required(),
    description_ar: Yup.string().required(),
    files: Yup.array().length(1, 'uploadFiles').required(),
});

export default function CreateEditReportPage({ reportInfoRequest, createEditRequest, reportId, fundId }) {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState(false);
    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            files: [],
        },
    });
    const history = useHistory();

    const isEdit = reportId !== 'create';

    const reportInfoQuery = useQuery(
        [FUND_REPORTS, { fundId, reportId }],
        () => reportInfoRequest(),
        {
            onSuccess: (data) => {
                reset({ ...data });
            },
            enabled: isEdit,
        },
    );

    const handleFormSubmission = async (values) => {
        const form = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === 'files') {
                values.files.forEach((fileObject) => {
                    if (fileObject.file instanceof File) {
                        form.append(`files[${fileObject.id}]`, fileObject.file);
                    }
                });
            } else {
                form.append(key, values[key]);
            }
        });

        if (isEdit) {
            form.append('_method', 'put');
        }

        setProgress(CREATE_EDIT_BTN, true);
        setIsUploading(true);
        try {
            await createEditRequest({ isEdit, form });
            reportInfoQuery.refetch();
            history.push(`/app/funds/${fundId}/reports`);
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CREATE_EDIT_BTN, false);
        setIsUploading(false);
    };

    return (
        <>
            <Breadcrumbs className="mb-5">
                <Link component={NavLink} to={`/app/funds`}>
                    {t('funds.funds')}
                </Link>
                <Link component={NavLink} to={`/app/funds`}>
                    #{fundId}
                </Link>
                <Link component={NavLink} to={`/app/funds/${fundId}/reports`}>
                    {t('funds.reports.reports')}
                </Link>
                {isEdit ? <Typography color="textPrimary">#{fundId}</Typography> : null}
            </Breadcrumbs>
            <Card>
                {reportInfoQuery.isLoading ? (
                    <CardContent>
                        <FormSkeleton inputsCount={5} />
                    </CardContent>
                ) : (
                    <form onSubmit={handleSubmit(handleFormSubmission)}>
                        <div className="py-3.5 px-5 font-bold text-2xl">
                            {isEdit
                                ? t('funds.reports.edit', {
                                      name: `${reportInfoQuery.data?.title_en}`,
                                  })
                                : t('funds.reports.createReport')}
                        </div>
                        <Divider />
                        <CardContent className="my-3">
                            <div className="space-y-4">
                                <div className="space-y-4">
                                    <InputController
                                        control={control}
                                        name="title_en"
                                        label={t('funds.reports.title(En)')}
                                    />
                                    <InputController
                                        control={control}
                                        name="title_ar"
                                        label={t('funds.reports.title(Ar)')}
                                    />
                                </div>
                                <EditorController
                                    control={control}
                                    name="description_en"
                                    label={t('funds.reports.description(En)')}
                                />
                                <EditorController
                                    control={control}
                                    name="description_ar"
                                    label={t('funds.reports.description(Ar)')}
                                />
                                <UploaderController
                                    name="files"
                                    control={control}
                                    disabled={isUploading}
                                    label={t('funds.reports.uplaodFiles')}
                                />
                            </div>
                            <div className="flex justify-end mt-5">
                                <Btn type="submit" name={CREATE_EDIT_BTN} fullWidth={false}>
                                    {isEdit ? t('common.update') : t('common.create')}
                                </Btn>
                            </div>
                        </CardContent>
                    </form>
                )}
            </Card>
        </>
    );
}
