import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { FUND_REPORTS } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import ReportInfoDialogSkeleton from '../Skeletons/ReportInfoDialogSkeleton';

function ReportInfoDialog({ isOpen, onClose, reportInfo, fundId }) {
    const { t } = useTranslation();
    const reportInfoQuery = useQuery(
        [FUND_REPORTS, { id: reportInfo?.id }],
        () => http.get(`/listings/${fundId}/reports/${reportInfo?.id}`).then((response) => response.data.data),
        {
            enabled: Boolean(reportInfo?.id),
        },
    );

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogContent>
                {reportInfoQuery.isLoading ? (
                    <ReportInfoDialogSkeleton />
                ) : (
                    <>
                        <Typography variant="h6" className="mb-3">
                            {t('funds.reports.reportInfo')}
                        </Typography>
                        <div className="mb-5 space-y-5 divide-y divide-gray-300">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <Typography variant="subtitle1">{t('funds.reports.title(En)')}</Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        {reportInfoQuery.data?.title_en}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle1">{t('funds.reports.title(Ar)')}</Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        {reportInfoQuery.data?.title_ar}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle1">{t('funds.reports.description(En)')}</Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html: `${reportInfoQuery.data?.description_en}`,
                                        }}
                                    />
                                </div>
                                <div>
                                    <Typography variant="subtitle1">{t('funds.reports.description(Ar)')}</Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="body1"
                                        dangerouslySetInnerHTML={{
                                            __html: `${reportInfoQuery.data?.description_ar}`,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="pt-2">
                                <Typography variant="subtitle1">{t('funds.reports.files')}</Typography>

                                {reportInfo?.files.length ? (
                                    reportInfo?.files.map((item) => (
                                        <div>
                                            <div className="flex justify-between space-y-2">
                                                <Typography color="textSecondary" variant="subtitle1">
                                                    {item.name}
                                                </Typography>
                                                <a href={item.url}>
                                                    <GetAppRoundedIcon className="text-gray-500" />
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="grey.600">{t('funds.reports.sorryNoFilesToShow')}</Typography>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default ReportInfoDialog;
