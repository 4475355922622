import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { handleError } from '@aseel/http';
import { Yup } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { BtnBase, setProgress, Btn, InputController } from '@aseel/common-ui';

const schema = Yup.object().shape({
    comment: Yup.string().required(),
    replier_name: Yup.string().required(),
    replier_role: Yup.object().shape({
        ar: Yup.string().required(),
        en: Yup.string().required(),
    }),
});

const CHAT_SAVE_BUTTON = 'CHAT_SAVE_BUTTON';

export default function ReplyForm({ isEdit, reply, questionId, onToggleForm, discussionsQuery, submitRequest, fundId }) {
    const { control, handleSubmit, setError } = useForm({
        defaultValues: {
            comment: isEdit ? reply?.text : '',
            replier_name: isEdit ? reply?.replier_name : '',
            replier_role: {
                ar: isEdit ? reply?.replier_role_locales.ar : '',
                en: isEdit ? reply?.replier_role_locales.en : '',
            },
        },
        resolver: yupResolver(schema),
    });

    const { t } = useTranslation();

    const handleFormSubmission = async (data) => {
        setProgress(CHAT_SAVE_BUTTON, true);
        try {
            await submitRequest({ isEdit, questionId, reply, data, fundId });
            toast.success(t('funds.discussion.success'));
            discussionsQuery.refetch();
            onToggleForm();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CHAT_SAVE_BUTTON, false);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmission)}>
            <div className="grid md:grid-cols-3  gap-5 mb-3.5">
                <InputController control={control} name="replier_name" label={t('funds.discussion.respondentName')} />
                <InputController
                    control={control}
                    name="replier_role.en"
                    label={t('funds.discussion.respondentRoleEn')}
                />
                <InputController
                    control={control}
                    name="replier_role.ar"
                    label={t('funds.discussion.respondentRoleAr')}
                />
            </div>
            <InputController control={control} name="comment" multiline rows={4} label={t('funds.comment')} />
            <div className="text-end m-2 space-s-2">
                {isEdit ? (
                    <BtnBase onClick={onToggleForm} variant="text" size={'small'} fullWidth={false}>
                        {t('common.close')}
                    </BtnBase>
                ) : null}
                <Btn name={CHAT_SAVE_BUTTON} type="submit" size={'small'} fullWidth={false}>
                    {t('funds.send')}
                </Btn>
            </div>
        </form>
    );
}
