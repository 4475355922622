import useAuth from 'Hooks/UseAuth';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Yup } from '@aseel/common-utils';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getRecaptchaToken } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import CountryCodes from 'Constants/CountryCodes.json';
import CardWithPattern from 'Components/Cards/CardWithPattern';
import { Btn } from '@aseel/common-ui';
import {
    UploaderController,
    setProgress,
    InputController,
    PasswordInputController,
    SelectController,
} from '@aseel/common-ui';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const REGISTRATION_BTN = 'REGISTRATION_BTN';

const schema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'passwordRule'),
    phone_country_code: Yup.string().required().nullable(),
    phone: Yup.string().required(),
    description: Yup.string().required(),
    cr_number: Yup.string().required(),
    name: Yup.string().required(),
    cr: Yup.array().length(1, 'uploadCR').required(),
    cma_license: Yup.array().length(1, 'uploadCMALicense').required(),
});

function RegisterPage() {
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [isUploading, setIsUploading] = useState(false);
    const auth = useAuth();

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            phone_country_code: 'SA',
            cma_license: [],
            cr: [],
        },
    });

    const handleFormSubmission = async (values) => {
        const form = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === 'cr') {
                values.cr.forEach((e) => {
                    if (e.file instanceof File) {
                        form.append(`cr[${e.id}]`, e.file);
                    }
                });
            } else if (key === 'cma_license') {
                if (values.cma_license[0] instanceof File) {
                    form.append('cma_license', values[key][0]);
                }
            } else {
                form.append(key, values[key]);
            }
        });

        setProgress(REGISTRATION_BTN, true);
        setIsUploading(true);

        try {
            const token = await getRecaptchaToken(executeRecaptcha);

            if (Boolean(token)) {
                form.append('g_token', token);
            }

            await http.post('/register', form);
            await auth.refreshCsrf();
            await auth.fetchUserAndEntityProfile();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(REGISTRATION_BTN, false);
        setIsUploading(false);
    };

    return (
        <CardWithPattern className="py-28">
            <div className="grid grid-cols-1 lg:grid-cols-3">
                <div
                    className="relative px-6 py-10 overflow-hidden text-white bg-gradient-to-b from-teal-500 to-teal-600 sm:px-10 xl:p-12"
                    style={{ backgroundColor: 'rgba(31, 41, 55)' }}>
                    <div
                        className="absolute top-0 bottom-0 right-0 z-0 hidden w-1/2 pointer-events-none lg:block"
                        aria-hidden="true">
                        <svg
                            className="absolute inset-0 w-full h-full"
                            width={160}
                            height={678}
                            viewBox="0 0 160 678"
                            fill="none"
                            preserveAspectRatio="xMidYMid slice"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                fill="url(#linear3)"
                                fillOpacity=".1"
                            />
                            <defs>
                                <linearGradient
                                    id="linear3"
                                    x1="192.553"
                                    y1="325.553"
                                    x2="899.66"
                                    y2="1032.66"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#fff" />
                                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="lg:h-full lg:flex lg:flex-col lg:justify-between">
                        <div>
                            <Typography variant="h4" className="text-white">
                                {t('register.signUp')}
                            </Typography>
                            <p className="max-w-3xl mt-6 text-base text-teal-50">
                                Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet.
                                Sapien tortor lacus arcu.
                            </p>
                        </div>
                        <div className="relative z-10">
                            <div className="flex items-center mt-8 space-s-1">
                                <Typography variant="body2" className="text-gray-300">
                                    {t('register.haveAnAccount')}
                                </Typography>
                                <Typography variant="body2" component={Link} to="/sign-in">
                                    {t('register.logIn')}
                                </Typography>
                            </div>

                            <div className="mt-1">
                                <Typography variant="body2" className="text-gray-300" component={Link} to="/sign-in">
                                    {t('register.forgotYourPassword')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
                    <form onSubmit={handleSubmit(handleFormSubmission)}>
                        <div className="space-y-5">
                            <div className="space-y-5">
                                <Typography variant="h5">{t('register.companyInformation')}</Typography>
                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                                    <InputController control={control} name="name" label={t('register.companyName')} />
                                    <InputController
                                        control={control}
                                        name="cr_number"
                                        label={t('register.crNumber')}
                                    />
                                </div>
                                <InputController
                                    multiline
                                    rows="4"
                                    control={control}
                                    name="description"
                                    label={t('register.description')}
                                />
                                <UploaderController
                                    name="cr"
                                    control={control}
                                    disabled={isUploading}
                                    label={t('register.cr')}
                                />
                                <UploaderController
                                    control={control}
                                    name="cma_license"
                                    disabled={isUploading}
                                    label={t('register.cmaLicense')}
                                    single
                                />
                            </div>
                            <Typography variant="h5">{t('register.yourAccInformation')}</Typography>
                            <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 md:grid-cols-2">
                                <InputController control={control} name="first_name" label={t('register.firstName')} />
                                <InputController control={control} name="last_name" label={t('register.lastName')} />
                                <InputController control={control} name="email" label={t('register.email')} />
                                <PasswordInputController
                                    control={control}
                                    name="password"
                                    label={t('register.password')}
                                    hasCheckList
                                />
                                <SelectController
                                    control={control}
                                    name="phone_country_code"
                                    label={t('register.countryCode')}
                                    options={CountryCodes}
                                    getOptionValue={(option) => option.Iso2}
                                    getOptionLabel={(option) => option.Dial}
                                />
                                <InputController name="phone" control={control} label={t('register.phone')} />
                            </div>
                        </div>
                        <div className="flex justify-end mt-5">
                            <Btn type="submit" name={REGISTRATION_BTN} fullWidth={false}>
                                {t('common.register')}
                            </Btn>
                        </div>
                    </form>
                </div>
            </div>
        </CardWithPattern>
    );
}
export default RegisterPage;
