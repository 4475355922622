import React, { useState } from 'react';
import { Chip, IconButton, Paper } from '@mui/material';
import { DataGrid, ResourceListSkeleton, ResourcesList } from '@aseel/common-ui';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Edit from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { USERS } from 'Constants/QueriesKeys';
import ViewUserDialog from './Components/ViewUserDialog';
import { green, red } from '@mui/material/colors';

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
    joined: {
        backgroundColor: green[100],
        color: green[900],
    },
    notJoined: {
        backgroundColor: red[100],
        color: red[900],
    },
};

// Component goes here
const UsersPage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);

    const usersQuery = useQuery([USERS, { page }], () =>
        http.get(`/users`, { params: { page } }).then(({ data }) => data),
    );

    const columns = [
        {
            field: 'full_name',
            headerName: t('users.full_name'),
            minWidth: 200,
            flex: 1,
            editable: false,
        },
        {
            field: 'email',
            headerName: t('users.email'),
            minWidth: 200,
            flex: 1,
            editable: false,
        },
        {
            field: 'did_join',
            headerName: t('users.didJoin'),
            flex: 1,
            minWidth: 50,
            renderCell: (row) => (
                <Chip
                    size="small"
                    label={row.row.did_join ? t('users.joined') : t('users.notJoined')}
                    sx={row.row.did_join ? styles.joined : styles.notJoined}
                />
            ),
        },
        {
            field: 'actions',
            headerName: t('users.actions'),
            minWidth: 200,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconButton
                            onClick={() => {
                                handleViewUser(row);
                            }}
                            size="large">
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                history.push(`${path}/${row.id}/edit`);
                            }}
                            size="large">
                            <Edit />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    const handleViewUser = (userData) => {
        setSelectedUser(userData);
        setIsOpen(true);
    };

    if (usersQuery.isLoading) {
        return <ResourceListSkeleton />;
    }

    return (
        <div>
            <ResourcesList
                headerProps={{
                    title: t('users.title'),
                    EndSide: (
                        <div>
                            <IconButton onClick={usersQuery.refetch} size="large">
                                <RefreshIcon />
                            </IconButton>
                            <IconButton component={Link} to={`${path}/create`} size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </div>
                    ),
                }}
                Section={
                    <div className="mt-8">
                        <div className={styles.tableSize}>
                            <Paper>
                                <DataGrid
                                    pagination
                                    rowCount={usersQuery.data?.meta.total}
                                    paginationMode="server"
                                    onPageChange={(newPage) => setPage(newPage)}
                                    loading={usersQuery.isFetching}
                                    rows={usersQuery.data?.data || []}
                                    columns={columns}
                                    pageSize={usersQuery.data?.meta.per_page}
                                />
                            </Paper>
                        </div>
                    </div>
                }
            />
            <ViewUserDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                userId={selectedUser?.id}
                setSelectedUser={setSelectedUser}
            />
        </div>
    );
};

export default UsersPage;
