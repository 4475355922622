import React from 'react';
import { ButtonBase, Card, CardContent, Typography } from '@mui/material';
import FundInformationSkeleton from './FundInformationSkeleton';
import { Skeleton } from '@mui/material';

function FundRevisionsSkeleton() {
    return (
        <div>
            <div className="lg:flex lg:h-full">
                <div className="w-full flex items-stretch space-y-0 space-s-4 mb-4 overflow-x-auto p-1 lg:w-1/3 lg:block lg:space-s-0 lg:me-5 lg:overflow-y-auto lg:h-full lg:space-y-4 lg:mb-0 px-1.5">
                    <div>
                        <Card className="w-56 h-full lg:h-auto lg:w-auto">
                            <ButtonBase
                                component="div"
                                className="flex items-center justify-start lg:block w-full h-full">
                                <CardContent className="py-8">
                                    <Typography variant="h5">
                                        <Skeleton width={110} />
                                    </Typography>
                                </CardContent>
                            </ButtonBase>
                        </Card>
                    </div>
                    {Array(3)
                        .fill(0)
                        .map(() => (
                            <div>
                                <Card className="w-56 lg:w-auto">
                                    <ButtonBase component="div" className="block w-full">
                                        <CardContent>
                                            <div className="space-y-5">
                                                <div>
                                                    <Typography variant="body2" className="text-gray-300">
                                                        <Skeleton className="md:w-1/2" />
                                                    </Typography>
                                                    <Typography>
                                                        <Skeleton className="md:w-1/4" />
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Typography variant="body2" className="text-gray-300">
                                                        <Skeleton className="md:w-1/2" />
                                                    </Typography>
                                                    <Typography>
                                                        <Skeleton className="md:w-1/4" />
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </ButtonBase>
                                </Card>
                            </div>
                        ))}
                </div>

                <div className="lg:w-2/3 lg:overflow-y-auto lg:p-1 px-1.5">
                    <Card className="mb-5">
                        <CardContent>
                            <div className="flex divide-y-4 divide-black">
                                <form className="w-full space-y-2.5">
                                    <Typography variant="h2">
                                        <Skeleton />
                                    </Typography>
                                    <Skeleton variant="rectangular" height={100} />
                                    <div className="flex justify-end">
                                        <Skeleton className="md:w-1/12 w-2/12 h-10" />
                                    </div>
                                </form>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="mb-5">
                        <CardContent>
                            <Typography variant="h6" className="mb-1">
                                <Skeleton className="md:w-1/4" />
                            </Typography>
                            <div className="whitespace-pre-line">
                                <Skeleton className="md:w-2/12" />
                            </div>
                        </CardContent>
                    </Card>

                    <FundInformationSkeleton informationContainerClassName="grid grid-cols-2 gap-10 p-5" />
                </div>
            </div>
        </div>
    );
}

export default FundRevisionsSkeleton;
