import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { Yup, i18n } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { approvalStatus, Scope } from '@aseel/constants';
import { FUNDS, FUND_REVIEWS } from 'Constants/QueriesKeys';
import FundInformation from '../FundInformation/FundInformation';
import { approvalStatusModalTypes } from '../../StatusChip/ApprovalStatusChip';
import ApprovalStatusChip from '../../StatusChip/ApprovalStatusChip';
import { ButtonBase, Card, CardContent, Typography } from '@mui/material';
import FundRevisionsSkeleton from '../../Skeletons/Funds/FundRevisionsSkeleton';
import { Btn, InputController, SelectController, setProgress } from '@aseel/common-ui';

const schema = Yup.lazy((values) => {
    let comment = Yup.string();

    if (values.status != approvalStatus.approved) {
        comment = comment.required();
    }

    return Yup.object().shape({
        comment,
        status: Yup.string().required().nullable(),
    });
});

const styles = {
    selectedRevision: {
        border: 3,
        borderColor: 'primary.main',
    },
};

const statusDescriptions = () => [
    {
        value: approvalStatus.pendingReview,
        label: i18n.t('common.pendingReview'),
    },
    {
        value: approvalStatus.inReview,
        label: i18n.t('common.inReview'),
    },
    {
        value: approvalStatus.approved,
        label: i18n.t('common.approved'),
    },
    {
        value: approvalStatus.rejected,
        label: i18n.t('common.rejected'),
    },
    {
        value: approvalStatus.needMoreInfo,
        label: i18n.t('common.needMoreInfo'),
    },
];
const UPDATE_STATUS_BTN = 'UPDATE_STATUS_BTN';

function FundRevisions({ fundId, getFundRequest, getRevisionsRequest, updateStatusRequest, scope }) {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(null);

    const form = useForm({
        resolver: yupResolver(schema),
    });
    const selectedStatus = form.watch('status');

    const revisionsQuery = useQuery([FUND_REVIEWS, { id: fundId }], getRevisionsRequest);

    const fundQuery = useQuery([FUNDS, { id: fundId }], getFundRequest, {
        onSuccess: (data) => {
            form.reset({
                comment: '',
                status: data.review_status.value,
            });
        },
    });

    if (revisionsQuery.isLoading || fundQuery.isLoading) {
        return <FundRevisionsSkeleton />;
    }

    const handleSubmitStatus = async (values) => {
        setProgress(UPDATE_STATUS_BTN, true);
        try {
            await updateStatusRequest(values);
            revisionsQuery.refetch();
            fundQuery.refetch();
        } catch (error) {
            handleError(error, { setError: form.setError });
        }
        setProgress(UPDATE_STATUS_BTN, false);
    };

    return (
        <div className="lg:flex lg:h-full">
            <div className="w-full flex items-stretch space-y-0 space-s-4 mb-4 overflow-x-auto p-1 lg:w-1/3 lg:block lg:space-s-0 lg:me-5 lg:overflow-y-auto lg:h-full lg:space-y-4 lg:mb-0">
                <div>
                    <Card
                        className="w-56 h-full lg:h-auto lg:w-auto"
                        sx={selectedIndex === null ? styles.selectedRevision : undefined}>
                        <ButtonBase
                            onClick={() => setSelectedIndex(null)}
                            component="div"
                            className="flex items-center justify-start lg:block w-full h-full">
                            <CardContent className="py-8">
                                <Typography variant="h6">{t('funds.currentFundVersion')}</Typography>
                            </CardContent>
                        </ButtonBase>
                    </Card>
                </div>
                {revisionsQuery.data?.map((item, index) => (
                    <div>
                        <Card
                            className="w-56 lg:w-auto"
                            sx={selectedIndex === index ? styles.selectedRevision : undefined}>
                            <ButtonBase
                                onClick={() => setSelectedIndex(index)}
                                component="div"
                                className="block w-full">
                                <CardContent>
                                    <div className="space-y-5">
                                        <div>
                                            <Typography color="grey.600" variant="body2">
                                                {t('funds.revisionDate')}
                                            </Typography>
                                            <Typography>{item.created_at}</Typography>
                                        </div>
                                        {scope === Scope.admin ? (
                                            <div>
                                                <Typography color="grey.600" variant="body2">
                                                    {t('funds.auditorName')}
                                                </Typography>
                                                <Typography>{item.auditor.name}</Typography>
                                            </div>
                                        ) : null}

                                        {item.comment ? (
                                            <div className="hidden lg:block">
                                                <Typography color="grey.600" className="break-words" variant="body2">
                                                    {t('funds.auditorComment')}
                                                </Typography>
                                                <Typography>{item.comment}</Typography>
                                            </div>
                                        ) : null}

                                        <div>
                                            <Typography color="grey.600" variant="body2">
                                                {t('common.status')}
                                            </Typography>
                                            <ApprovalStatusChip
                                                status={item.listing_info.review_status.value}
                                                label={item.listing_info.review_status.description}
                                                modalType={approvalStatusModalTypes.general}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </ButtonBase>
                        </Card>
                    </div>
                ))}
            </div>

            <div className="lg:w-2/3 lg:overflow-y-auto lg:p-1">
                {selectedIndex === null && scope === Scope.admin ? (
                    <Card className="mb-5">
                        <CardContent>
                            <div className="flex divide-y-4 divide-black">
                                <form onSubmit={form.handleSubmit(handleSubmitStatus)} className="w-full space-y-2.5">
                                    <SelectController
                                        name="status"
                                        control={form.control}
                                        label={t('bankAcc.status')}
                                        options={statusDescriptions()}
                                        menuPortalTarget={document.body}
                                    />
                                    {selectedStatus != approvalStatus.approved ? (
                                        <InputController
                                            name="comment"
                                            control={form.control}
                                            label={t('funds.comment')}
                                            multiline
                                            rows={7}
                                        />
                                    ) : null}
                                    <div className="flex justify-end">
                                        <Btn type="submit" name={UPDATE_STATUS_BTN} fullWidth={false}>
                                            {t('common.update')}
                                        </Btn>
                                    </div>
                                </form>
                            </div>
                        </CardContent>
                    </Card>
                ) : null}

                {selectedIndex === null ||
                scope === Scope.admin ||
                revisionsQuery.data[selectedIndex].comment ? null : (
                    <Card className="mb-5">
                        <CardContent>
                            <Typography variant="h6" className="mb-1">
                                {t('funds.auditorComment')}
                            </Typography>
                            <div className="whitespace-pre-line">{revisionsQuery.data[selectedIndex].comment}</div>
                        </CardContent>
                    </Card>
                )}

                <FundInformation
                    sx={{ px: 0 }}
                    data={selectedIndex === null ? fundQuery.data : revisionsQuery.data[selectedIndex].listing_info}
                    statusesContainerClassName="flex space-x-5 text-center"
                    informationContainerClassName="grid grid-cols-2 gap-10 p-5"
                    areActionBtnsVisible={false}
                />
            </div>
        </div>
    );
}

export default FundRevisions;
