import React from 'react';
import { EditorEnAr, UploaderController } from '@aseel/common-ui';
import { useRootProps } from '../Utils/RootPropsContext';
import { useTranslation } from 'react-i18next';

// Component goes here
const DetailsTab = () => {
    const { control } = useRootProps();
    const { t } = useTranslation();

    return (
        <div className="space-y-4">
            <UploaderController  control={control} name="property_details.files" label={t('funds.uploadFiles')}  />
            <EditorEnAr control={control} arName="property_details.description_ar" enName="property_details.description_en" />
        </div>
    );
};

export default DetailsTab;
