import './index.css';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { setLocale } from 'yup';
import SignInPage from 'Pages/Auth/SignInPage';
import { useEffect, useState } from 'react';
import { http } from 'Utils/Http/Http';
import App from 'App';
import { AuthContext } from 'Contexts/AuthContext';
import BasicLayoutEntry from 'BasicLayoutEntry';
import { GuardedRoute } from '@aseel/common-utils';
import ApprovedEntityGuard from 'Utils/Guards/ApprovedEntityGuard';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import SendResetLinkComponent from 'Pages/Auth/SendResetLinkPage';
import ResetPasswordComponent from 'Pages/Auth/ResetPasswordPage';

setLocale({
    mixed: {
        required: 'required',
    },
    string: {
        required: 'required',
    },
});

function Entry() {
    const { t } = useTranslation();
    const [isBootstrapping, setIsBootstrapping] = useState(true);
    const [auth, setAuth] = useState({
        user: null,
        entity: null,
        fetchUserAndEntityProfile: async () => {
            let entity = null;

            try {
                const response = await http.get('/entity/profile');
                entity = response.data.data;

                const userProfileResponse = await http.get('/auth');
                setAuth((auth) => ({ ...auth, entity, user: userProfileResponse.data.data }));
            } catch (error) {
                setAuth((auth) => ({ ...auth, entity: null, user: null }));
            }
        },
        fetchUserProfile: () => {
            return http
                .get('/auth')
                .then((response) => {
                    setAuth((auth) => ({ ...auth, user: response.data.data }));
                })
                .catch(() => {
                    setAuth((auth) => ({ ...auth, user: null }));
                });
        },
        fetchEntityProfile: () => {
            return http
                .get('/entity/profile')
                .then((response) => {
                    setAuth((auth) => ({ ...auth, entity: response.data.data }));
                })
                .catch(() => {
                    setAuth((auth) => ({ ...auth, entity: null }));
                });
        },
        refreshCsrf: () => {
            return http.get(`/sanctum/csrf-cookie`, {
                baseURL: process.env.REACT_APP_BASE_URL,
            });
        },
        logout: () => {
            return http
                .post('/api/logout', undefined, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                })
                .then(() => {
                    setAuth({ ...auth, user: null });
                    return auth.refreshCsrf();
                });
        },
    });

    useEffect(() => {
        setIsBootstrapping(true);
        auth.fetchUserProfile()
            .then(() => auth.fetchEntityProfile())
            .then(() => auth.refreshCsrf())
            .finally(() => setIsBootstrapping(false));
    }, []);

    return (
        <AuthContext.Provider value={auth}>
            {isBootstrapping ? (
                <div className="flex items-center justify-center h-screen">{t('common.loading')}</div>
            ) : (
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/app/dashboard" />
                    </Route>
                    <GuardedRoute guards={[GuestGuard]} exact path="/sign-in" component={SignInPage} />
                    <GuardedRoute guards={[AuthGuard, ApprovedEntityGuard]} path="/app" component={App} />
                    <Route exact path="/forgot-password" component={SendResetLinkComponent} />
                    <Route exact path="/reset-password" component={ResetPasswordComponent} />

                    <Route path="/" component={BasicLayoutEntry} />
                </Switch>
            )}
        </AuthContext.Provider>
    );
}

export default Entry;
