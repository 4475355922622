import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MoneyIcon from '@mui/icons-material/Money';
import ForumIcon from '@mui/icons-material/Forum';
import DescriptionIcon from '@mui/icons-material/Description';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import LinearScaleRoundedIcon from '@mui/icons-material/LinearScaleRounded';
import { useHistory } from 'react-router-dom';

export default function FundActions({ fundId, isVisible = true, containerClassName, iconSize, ...otherProps }) {
    const { t } = useTranslation();
    const history = useHistory();

    const actions = [
        {
            name: t('funds.fundInfo'),
            link: `/app/funds/${fundId}/show`,
            icon: VisibilityRoundedIcon,
        },
        {
            name: t('funds.edit'),
            link: `/app/funds/${fundId}`,
            icon: EditIcon,
        },
        {
            name: t('funds.iBansAndInvestors'),
            link: `/app/funds/${fundId}/v-accounts`,
            icon: AccountBalanceWalletIcon,
        },
        {
            name: t('funds.payouts.payouts'),
            link: `/app/funds/${fundId}/payouts`,
            icon: MoneyIcon,
        },
        {
            name: t('funds.discussions'),
            link: `/app/funds/${fundId}/discussions`,
            icon: ForumIcon,
        },
        {
            name: t('funds.reports.reports'),
            link: `/app/funds/${fundId}/reports`,
            icon: DescriptionIcon,
        },
        {
            name: t('funds.fundRevisions'),
            link: `/app/funds/${fundId}/revisions`,
            icon: RateReviewRoundedIcon,
        },
        {
            name: t('funds.valuations.valuations'),
            link: `/app/funds/${fundId}/valuations`,
            icon: LinearScaleRoundedIcon,
        },
    ];

    return isVisible ? (
        <div className={containerClassName}>
            {actions.map((item) => (
                <Tooltip title={item.name} placement="top">
                    <IconButton onClick={() => history.push(item.link)} {...otherProps} size="large">
                        <item.icon {...otherProps} style={{ fontSize: iconSize }} />
                    </IconButton>
                </Tooltip>
            ))}
        </div>
    ) : null;
}
