import React from 'react';
import { Typography } from '@mui/material';

const SectionLayout = ({ sectionTitle, sectionContent, isLoading }) => {
    return (
        <div>
            <Typography className="mb-4" variant="h4" component="h1">
                {sectionTitle}
            </Typography>

            {sectionContent}
        </div>
    );
};

export default SectionLayout;
