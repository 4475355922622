import React from 'react';
import { http } from 'Utils/Http/Http';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { useTranslation } from 'react-i18next';
import { getRecaptchaToken } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryParams, Yup } from '@aseel/common-utils';
import { Link, useHistory, Redirect } from 'react-router-dom';
import CardWithPattern from 'Components/Cards/CardWithPattern';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Btn, InputController, PasswordInputController, setProgress } from '@aseel/common-ui';

const CONFIRMATION_BTN = 'CONFIRMATION_BTN';

const schema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'passwordRule'),
    password_confirmation: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'passwordRule',
    ),
});

export default function ConfirmInvitationPage() {
    const query = useQueryParams();
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const history = useHistory();
    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: query.get('email'),
        },
    });

    if (query.get('token') === null || query.get('email') === null) {
        return <Redirect to="/sign-in" />;
    }

    const handleFormSubmission = async (values) => {
        setProgress(CONFIRMATION_BTN, true);
        const token = query.get('token');
        const form = { ...values };
        form.token = token;

        try {
            const gToken = await getRecaptchaToken(executeRecaptcha);

            await http.post('/sign-up/invitations', { ...form, g_token: gToken });
            history.push('/sign-in');
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CONFIRMATION_BTN, false);
    };

    return (
        <CardWithPattern className="py-28">
            <div className="grid grid-cols-1 lg:grid-cols-3">
                <div
                    className="relative px-6 py-10 overflow-hidden text-white bg-gradient-to-b from-teal-500 to-teal-600 sm:px-10 xl:p-12"
                    style={{ backgroundColor: 'rgba(31, 41, 55)' }}>
                    <div
                        className="absolute top-0 bottom-0 right-0 z-0 hidden w-1/2 pointer-events-none lg:block"
                        aria-hidden="true">
                        <svg
                            className="absolute inset-0 w-full h-full"
                            width={160}
                            height={678}
                            viewBox="0 0 160 678"
                            fill="none"
                            preserveAspectRatio="xMidYMid slice"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                fill="url(#linear3)"
                                fillOpacity=".1"
                            />
                            <defs>
                                <linearGradient
                                    id="linear3"
                                    x1="192.553"
                                    y1="325.553"
                                    x2="899.66"
                                    y2="1032.66"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#fff" />
                                    <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="lg:h-full lg:flex lg:flex-col lg:justify-between">
                        <div>
                            <Typography variant="h4" className="text-white">
                                {t('register.invitation')}
                            </Typography>
                        </div>
                        <div className="relative z-10">
                            <div className="flex items-center mt-8 space-s-1">
                                <Typography variant="body2" className="text-gray-300">
                                    {t('register.haveAnAccount')}
                                </Typography>
                                <Typography variant="body2" component={Link} to="/sign-in">
                                    {t('register.logIn')}
                                </Typography>
                            </div>

                            <div className="mt-1">
                                <Typography variant="body2" className="text-gray-300" component={Link} to="/sign-in">
                                    {t('register.forgotYourPassword')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
                    <form onSubmit={handleSubmit(handleFormSubmission)}>
                        <div className="space-y-5">
                            <Typography variant="h5">{t('register.confirmYourAccountNow')}</Typography>
                        </div>
                        <div className="mt-5">
                            <InputController name="email" label={t('register.email')} control={control} disabled />
                            <div className="grid gap-5 py-5 md:grid-cols-2">
                                <PasswordInputController
                                    control={control}
                                    name="password"
                                    label={t('register.password')}
                                    hasCheckList
                                />
                                <PasswordInputController
                                    label={t('register.passwordConfirmation')}
                                    control={control}
                                    name="password_confirmation"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <Btn fullWidth={false} type="submit" name={CONFIRMATION_BTN}>
                                {t('common.update')}
                            </Btn>
                        </div>
                    </form>
                </div>
            </div>
        </CardWithPattern>
    );
}
