import { Card } from '@mui/material';
import React from 'react';
import DualLocaleDisplayer from '../../Components/DualLocaleDisplayer';
import FilesDisplayer from '../FilesDisplayer';

export default function RisksTab({ text, files }) {
    return (
        <>
            <FilesDisplayer files={files} />
            <Card className="mt-2">
                <DualLocaleDisplayer text={text} />
            </Card>
        </>
    );
}
