import React from 'react';
import useAuth from 'Hooks/UseAuth';
import { http } from 'Utils/Http/Http';
import { Scope } from '@aseel/constants';
import { useParams } from 'react-router';
import FundCreateEditForm from '@aseel/common-components/src/Funds/CreateOrUpdateFund/FundCreateEditForm';

export default function FundCreateEditPage() {
    const { fundId } = useParams();
    const { entity } = useAuth();

    const authorizedEntityName = entity.name;

    const uploadImagesRequest = ({ returnedFundId, formData }) => {
        return http.post(`/listings/${returnedFundId}/images`, formData);
    };

    const uploadFilesRequest = ({ returnedFundId, formData }) => {
        return http.post(`/listings/${returnedFundId}/files`, formData);
    };

    return (
        <div>
            <FundCreateEditForm
                fundId={fundId}
                scope={Scope.authorizedEntity}
                uploadFilesRequest={uploadFilesRequest}
                uploadImagesRequest={uploadImagesRequest}
                infoBasedOnScope={{ entityName: authorizedEntityName }}
                getFundRequest={() => http.get(`/listings/${fundId}?edit=1`).then((response) => response.data.data)}
            />
        </div>
    );
}
