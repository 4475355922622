import React from 'react';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router-dom';
import CreateEditReportComponent from '@aseel/common-components/src/Funds/Reports/Components/CreateEditReportComponent';

export default function CreateEditReportPage() {
    const { fundId, reportId } = useParams();
    const reportInfoRequest = () => {
        return http.get(`/listings/${fundId}/reports/${reportId}`).then((response) => response.data.data);
    };

    const createEditRequest = ({ isEdit, form }) => {
        http.request({
            method: 'POST',
            url: isEdit ? `/listings/${fundId}/reports/${reportId}` : `/listings/${fundId}/reports`,
            data: form,
        });
    };

    return (
        <div>
            <CreateEditReportComponent
                reportInfoRequest={reportInfoRequest}
                createEditRequest={createEditRequest}
                fundId={fundId}
                reportId={reportId}
            />
        </div>
    );
}
