import i18n from 'Utils/I18n';

export const footerSections = () => [
    {
        title: i18n.t('footer.legal'),
        links: [
            {
                text: i18n.t('footer.privacyPolicy'),
                link: `${process.env.REACT_APP_HOME_URL}/privacy-policy`,
                type: 'url',
            },
            {
                text: i18n.t('footer.ShariaaCompliance'),
                link: `${process.env.REACT_APP_HOME_URL}/sharia-compliance`,
                type: 'url',
            },
            {
                text: i18n.t('footer.termsAndConditions'),
                link: `${process.env.REACT_APP_HOME_URL}/terms-and-conditions`,
                type: 'url',
            },
            {
                text: i18n.t('footer.codeOfConducts'),
                link: `${process.env.REACT_APP_HOME_URL}/code-of-conducts`,
                type: 'url',
            },
        ],
    },
    {
        title: i18n.t('footer.company'),
        links: [
            {
                text: i18n.t('footer.aboutUs'),
                link: `${process.env.REACT_APP_HOME_URL}/about-us`,
                type: 'url',
            },
            {
                text: i18n.t('footer.codeOfConducts'),
                link: `${process.env.REACT_APP_HOME_URL}/contact-us`,
                type: 'url',
            },
        ],
    },
];
