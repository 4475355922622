import React from 'react';
import { EditorEnAr } from '@aseel/common-ui';
import { useRootProps } from '../Utils/RootPropsContext';

export default function TermsAndConditions() {
    const { control } = useRootProps();

    return (
        <div className="space-y-5 py-2.5">
            <EditorEnAr
                control={control}
                arName="terms_conditions.description_ar"
                enName="terms_conditions.description_en"
            />
        </div>
    );
}
