import React from 'react';
import { Card, Skeleton } from '@mui/material';
import { Typography } from '@mui/material';
import { DataGrid, ResourcesList } from '@aseel/common-ui';

function FundReportsSkeleton() {
    const authEntitiesColumns = [
        {
            field: 'title',
            headerName: <Skeleton width="600px" height="15px" />,
            flex: 1,
            renderCell: () => {
                return <Skeleton width="500px" height="15px" />;
            },
        },
        {
            field: 'subject',
            headerName: <Skeleton width="600px" height="15px" />,
            flex: 1,
            renderCell: () => {
                return <Skeleton width="200px" height="15px" />;
            },
        },
        {
            field: 'object',
            headerName: <Skeleton width="600px" height="15px" />,
            flex: 0.5,
            renderCell: () => {
                return <Skeleton width="200px" height="15px" />;
            },
        },
    ];

    const authEntitiesRows = [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3,
        },
        {
            id: 4,
        },
        {
            id: 5,
        },
        {
            id: 6,
        },
    ];

    return (
        <>
            <ResourcesList
                BeforeHeader={
                    <Typography className="mb-5">
                        <Skeleton width="200px" />
                    </Typography>
                }
                headerProps={{
                    title: (
                        <Typography variant="h3">
                            <Skeleton width="200px" />
                        </Typography>
                    ),
                    EndSide: (
                        <Typography variant="h3">
                            <Skeleton width="150px" />
                        </Typography>
                    ),
                }}
            />
            <Card className="mx-3.5 mt-10">
                <DataGrid columns={authEntitiesColumns} rows={authEntitiesRows} hideFooter />
            </Card>
        </>
    );
}

export default FundReportsSkeleton;
