import React from 'react';
import { http } from 'Utils/Http/Http';
import { Scope } from '@aseel/constants';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { NavLink, useParams } from 'react-router-dom';
import FundIBanAndInvestorsComponent from '@aseel/common-components/src/Funds/FundIBanAndInvestors/FundIBanAndInvestorsComponent';

export default function FundIBanAndInvestorsPage() {
    const { t } = useTranslation();
    const { fundId } = useParams();

    const createIbanRequest = (currentTab) =>
        http.post(`/listings/${fundId}/wallets`, {
            type: currentTab,
        });

    const ibanInvestorsRequest = () => {
        return http.get(`/listings/${fundId}/investors`).then(({ data }) => data);
    };

    const ibanWalletRequest = () => {
        return http.get(`/listings/${fundId}/wallets`).then(({ data }) => data);
    };

    return (
        <div>
            <Breadcrumbs className="mb-4">
                <Link component={NavLink} to="/app/funds">
                    {t('funds.funds')}
                </Link>
                <Typography color="textPrimary">#{fundId}</Typography>
                <Typography color="textPrimary">{t('funds.iBansAndInvestors')}</Typography>
            </Breadcrumbs>
            <FundIBanAndInvestorsComponent
                fundId={fundId}
                scope={Scope.authorizedEntity}
                createIbanRequest={createIbanRequest}
                ibanWalletRequest={ibanWalletRequest}
                ibanInvestorsRequest={ibanInvestorsRequest}
            />
        </div>
    );
}
