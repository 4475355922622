import React from 'react';
import { EditorEnAr, UploaderController } from '@aseel/common-ui';
import { useRootProps } from '../Utils/RootPropsContext';
import { useTranslation } from 'react-i18next';

// Component goes here
const FinancialTab = () => {
    const { control } = useRootProps();
    const { t } = useTranslation();

    return (
        <div className="space-y-4">
            <UploaderController control={control} name="financial_details.files" label={t('funds.uploadFiles')}  />
            <EditorEnAr control={control} arName="financial_details.content_ar" enName="financial_details.content_en" />
        </div>
    );
};

export default FinancialTab;
