import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';

export const NavLinks = [
    { name: 'Home', path: '/app/dashboard', current: true, Icon: HomeRoundedIcon },
    { name: 'Users', path: '/app/users', current: true, Icon: AccountBoxIcon },
    { name: 'Funds', path: '/app/funds', current: true, Icon: BusinessIcon },
    { name: 'Bank Accounts', path: '/app/bank-accounts', current: false, Icon: AccountBalanceRoundedIcon },

];
