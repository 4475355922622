import useTranslation from '../../Hooks/UseTranslation';

export function GetTabs() {
    const { i18n } = useTranslation()

    return {
        details: {
            value: 1,
            label: i18n.t('funds.preview.tabs.details'),
        },
        dueDiligence: {
            value: 2,
            label: i18n.t('funds.preview.tabs.dueDiligence'),
        },
        location: {
            value: 3,
            label: i18n.t('funds.preview.tabs.location'),
        },
        risks: {
            value: 4,
            label: i18n.t('funds.preview.tabs.risks'),
            backendPath: 'risks',
        },
        financials: {
            value: 5,
            label: i18n.t('funds.preview.tabs.financials'),
        },
        team: {
            value: 6,
            label: i18n.t('funds.preview.tabs.teams'),
        },
        timeline: {
            value: 7,
            label: i18n.t('funds.preview.tabs.updates'),
        },
    };
}
