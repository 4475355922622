import React from 'react';
import { EditorEnAr, UploaderController } from '@aseel/common-ui';
import { useRootProps } from '../Utils/RootPropsContext';
import { useTranslation } from 'react-i18next';

// Component goes here
const RisksTab = () => {
    const { control } = useRootProps();
    const { t } = useTranslation();

    return (
        <div className="space-y-4">
            <UploaderController control={control} name="risks.files" label={t('funds.uploadFiles')}  />

            <EditorEnAr control={control} arName="risks.content_ar" enName="risks.content_en" />
        </div>
    );
};

export default RisksTab;
