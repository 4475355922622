import React from 'react';
import { Card, CardContent, Breadcrumbs, Link, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { http } from 'Utils/Http/Http';
import { handleError } from '@aseel/http';
import { useTranslation } from 'react-i18next';
import CountryCodes from 'Constants/CountryCodes.json';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSkeleton, setProgress } from '@aseel/common-ui';
import { InputController, SelectController, Btn, ResourcesList } from '@aseel/common-ui';
import { Yup } from '@aseel/common-utils';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { USERS } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';

const INVITE_UPDATE_BTN = 'INVITE_UPDATE_BTN';

const schema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().required(),
    phone_country_code: Yup.string().required().nullable(),
    phone: Yup.string().required(),
});

function CreateEditUserForm() {
    const { userId } = useParams();
    const isEdit = Boolean(userId);
    const history = useHistory();
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            phone_country_code: 'SA',
        },
    });

    const handleFormSubmission = async (values) => {
        setProgress(INVITE_UPDATE_BTN, true);
        try {
            await http.request({
                method: isEdit ? 'PUT' : 'POST',
                url: isEdit ? `/users/${userId}/` : `/users`,
                data: values,
            });
            history.goBack();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(INVITE_UPDATE_BTN, false);
    };

    const userInfoQuery = useQuery(
        [USERS, { userId }],
        () => http.get(`/users/${userId}`).then((response) => response.data.data),
        {
            enabled: Boolean(userId),
            onSuccess: (data) => {
                reset({
                    ...data,
                });
            },
        },
    );

    return (
        <ResourcesList
            BeforeHeader={
                <Breadcrumbs className="mb-4">
                    <Link component={NavLink} to="/app/users">
                        {t('users.title')}
                    </Link>
                    <Typography color="textPrimary">
                        {isEdit ? `#${userId} ${t('users.update')}` : t('users.invite')}
                    </Typography>
                </Breadcrumbs>
            }
            Section={
                <Card className="mt-4">
                    <CardContent>
                        {userInfoQuery.isLoading ? (
                            <FormSkeleton inputsCount={4} />
                        ) : (
                            <form onSubmit={handleSubmit(handleFormSubmission)}>
                                <div className="mb-3 font-bold text-2xl">
                                    {isEdit ? (
                                        <>
                                            {t('users.update')} {userInfoQuery.data?.first_name}{' '}
                                            {userInfoQuery.data?.last_name}
                                        </>
                                    ) : (
                                        t('users.invite')
                                    )}
                                </div>

                                <div className="space-y-4">
                                    <div>
                                        <InputController
                                            control={control}
                                            name="first_name"
                                            label={t('users.first_name')}
                                            className="col-span-3"
                                        />
                                    </div>
                                    <div>
                                        <InputController
                                            control={control}
                                            name="last_name"
                                            label={t('users.last_name')}
                                            className="col-span-3"
                                        />
                                    </div>
                                    <div>
                                        <InputController control={control} name="email" label={t('users.email')} />
                                    </div>
                                    <div className="grid gap-4 grid-cols-6">
                                        <SelectController
                                            control={control}
                                            name="phone_country_code"
                                            label={t('users.phone_country_code')}
                                            options={CountryCodes}
                                            getOptionValue={(option) => option.Iso2}
                                            getOptionLabel={(option) => option.Dial}
                                            className="col-span-2"
                                        />
                                        <InputController
                                            name="phone"
                                            control={control}
                                            label={t('users.phone')}
                                            className="col-span-4"
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end mt-3">
                                    <Btn type="submit" name={INVITE_UPDATE_BTN} fullWidth={false}>
                                        {isEdit ? t('users.update') : t('users.invite')}
                                    </Btn>
                                </div>
                            </form>
                        )}
                    </CardContent>
                </Card>
            }
        />
    );
}

export default CreateEditUserForm;
