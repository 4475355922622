import useAuth from 'Hooks/UseAuth';
import { Redirect } from 'react-router-dom';

export default function GuestGuard(next) {
    const { user } = useAuth();

    if (user === null) {
        return next();
    }

    return <Redirect to="/app/dashboard" />;
}
