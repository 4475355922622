import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AppLayout } from '@aseel/common-ui';
import { NavLinks } from 'Constants/NavLinks';
import UsersPage from 'Pages/Users/UsersPage';
import CreateEditUserForm from 'Pages/Users/Components/CreateEditUserForm';
import FundsPage from './Pages/Funds/FundsPage';
import FundCreateEditForm from 'Pages/Funds/FundCreateEditPage';
import FundIBanAndInvestorsPage from 'Pages/Funds/FundIBanAndInvestors/FundIBanAndInvestorsPage';
import FundDiscussionPage from 'Pages/Funds/Discussions/FundDiscussionPage';
import FundPayoutsPage from 'Pages/Funds/Payouts/FundPayoutsPage';
import PayoutDetailsPage from 'Pages/Funds/Payouts/Components/PayoutDetailsPage';
import FundReportsPage from 'Pages/Funds/Reports/FundReportsPage';
import ValuationsPage from 'Pages/Funds/Valuations/ValuationsPage';
import BankAccounts from 'Pages/BankAccounts/BankAccounts';
import FundInformationPage from 'Pages/Funds/FundInformation/FundInformationPage';
import FundRevisionsPage from 'Pages/Funds/FundRevisions/FundRevisionsPage';
import DashboardPage from 'Pages/Dashboard/DashboardPage';
import CreateEditReportPage from 'Pages/Funds/Reports/Components/CreateEditReportPage';
import useAuth from 'Hooks/UseAuth';

export default function App() {
    let { path } = useRouteMatch();
    const auth = useAuth();
    
    return (
        <AppLayout auth={auth} links={NavLinks}>
            <Switch>
                <Route exact path={`${path}/dashboard`} component={DashboardPage} />
                <Route exact path={`${path}/users`} component={UsersPage} />
                <Route exact path={`${path}/bank-accounts`} component={BankAccounts} />
                <Route exact path={`${path}/users/create`} component={CreateEditUserForm} />
                <Route exact path={`${path}/users/:userId/edit`} component={CreateEditUserForm} />
                <Route exact path={`${path}/funds`} component={FundsPage} />
                <Route exact path={`${path}/funds/:fundId`} component={FundCreateEditForm} />
                <Route exact path={`${path}/funds/:fundId/discussions`} component={FundDiscussionPage} />
                <Route exact path={`${path}/funds/:fundId/v-accounts`} component={FundIBanAndInvestorsPage} />
                <Route exact path={`${path}/funds/:fundId/payouts`} component={FundPayoutsPage} />
                <Route exact path={`${path}/funds/:fundId/payouts/:payoutId`} component={PayoutDetailsPage} />
                <Route exact path={`${path}/funds/:fundId/reports`} component={FundReportsPage} />
                <Route exact path={`${path}/funds/:fundId/reports/:reportId`} component={CreateEditReportPage} />
                <Route exact path={`${path}/funds/:fundId/valuations`} component={ValuationsPage} />
                <Route exact path={`${path}/funds/:fundId/show`} component={FundInformationPage} />
                <Route exact path={`${path}/funds/:fundId/revisions`} component={FundRevisionsPage} />
            </Switch>
        </AppLayout>
    );
}
