import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useState } from 'react';
import DueDiligenceTab from './TabsSection/DueDiligenceTab';
import DetailsTab from './TabsSection/DetailsTab';
import LocationTab from './TabsSection/LocationTab';
import TeamsTab from './TabsSection/Teams/TeamsTab';
import FinancialTab from './TabsSection/FinancialTab';
import RisksTab from './TabsSection/RisksTab';
import TimelineTab from './TabsSection/TimelineTab';
import { tabs as tabsValues } from './FundCreateEditForm';
import { Card, CardContent } from '@mui/material';
import ProofDocuments from './TabsSection/ProofDocuments';
import RoundedTabs from '@aseel/common-ui/src/Components/Tabs/RoundedTabs';
import TermsAndConditions from './TabsSection/TermsAndConditions';

// Component goes here
const TabsPanel = () => {
    const { t } = useTranslation();

    const tabs = [
        {
            value: tabsValues.due_diligence,
            label: t('funds.due_diligence'),
            Component: DueDiligenceTab,
        },
        {
            value: tabsValues.property_details,
            label: t('funds.details'),
            Component: DetailsTab,
        },
        {
            value: tabsValues.location,
            label: t('funds.location'),
            Component: LocationTab,
        },
        {
            value: tabsValues.risks,
            label: t('funds.risks'),
            Component: RisksTab,
        },
        {
            value: tabsValues.financial_details,
            label: t('funds.financial_details'),
            Component: FinancialTab,
        },
        {
            value: tabsValues.team,
            label: t('funds.team'),
            Component: TeamsTab,
        },
        {
            value: tabsValues.timeline,
            label: t('funds.timeline'),
            Component: TimelineTab,
        },
        {
            value: tabsValues.proof_docs,
            label: t('funds.proofDocuments'),
            Component: ProofDocuments,
        },
        {
            value: tabsValues.terms_conditions,
            label: t('funds.termsAndConditions'),
            Component: TermsAndConditions,
        },
    ];

    const [currentTabValue, setCurrentTabValue] = useState(tabsValues.due_diligence);

    // Handlers
    const handleTabChange = (event, newValue) => {
        setCurrentTabValue(newValue);
    };

    const { Component, props } = tabs.find((tab) => tab.value === currentTabValue);

    return (
        <Box>
            <RoundedTabs
                tabs={tabs}
                value={currentTabValue}
                onChange={handleTabChange}
                centered={false}
                variant="scrollable"
                scrollButtons="auto"
            />
            <Card>
                <CardContent>
                    <Component {...props} />
                </CardContent>
            </Card>
        </Box>
    );
};

export default TabsPanel;
