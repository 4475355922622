import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DualLocaleDisplayer from '../../Components/DualLocaleDisplayer';
import FilesDisplayer from '../FilesDisplayer';

export default function LocationTab({ text, mapUrl, files }) {
    const { t } = useTranslation();

    return (
        <div className="space-y-2.5">
            <FilesDisplayer files={files} />
            <Card>
                <CardContent>
                    <div>
                        <div>
                            <Typography variant="body2" color="textSecondary">
                                {t('funds.googleMapUrl')}
                            </Typography>
                            <Typography variant="body1">{mapUrl || '---'}</Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card >
                <DualLocaleDisplayer text={text} />
            </Card>
        </div>
    );
}
