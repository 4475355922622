import React from 'react';
import { EditorEnAr, UploaderController } from '@aseel/common-ui';
import { useRootProps } from '../Utils/RootPropsContext';
import { useTranslation } from 'react-i18next';

// Component goes here
const DueDiligenceTab = () => {
    const { control } = useRootProps();
    const { t } = useTranslation();

    return (
        <div className="space-y-4">
            <UploaderController control={control} name="due_diligence.files" label={t('funds.uploadFiles')}  />
            <EditorEnAr control={control} arName="due_diligence.content_ar" enName="due_diligence.content_en" />
        </div>
    );
};

export default DueDiligenceTab;
