import React from 'react';
import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

function ReportInfoDialogSkeleton({ isOpen, onClose, reportInfo, fundId }) {
    return (
        <div>
            <Typography variant="h6" className="mb-3">
                <Skeleton className="lg:w-1/4" />
            </Typography>
            <div className="divide-y divide-gray-300 space-y-5 mb-5">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Typography variant="subtitle1">
                            <Skeleton className="lg:w-1/4" />
                        </Typography>
                        <Typography color="textSecondary" variant="body1">
                            <Skeleton className="lg:w-1/2" />
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="subtitle1">
                            <Skeleton className="lg:w-1/4" />
                        </Typography>
                        <Typography color="textSecondary" variant="body1">
                            <Skeleton className="lg:w-1/2" />
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="subtitle1">
                            <Skeleton className="lg:w-1/4" />
                        </Typography>
                        <Typography>
                            <Skeleton className="lg:w-1/2" />
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="subtitle1">
                            <Skeleton className="lg:w-1/4" />
                        </Typography>
                        <Typography>
                            <Skeleton className="lg:w-1/2" />
                        </Typography>
                    </div>
                </div>
                <div className="pt-2">
                    <Typography variant="subtitle1">
                        <Skeleton className="lg:w-1/4" />
                    </Typography>
                    <span>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ReportInfoDialogSkeleton;
