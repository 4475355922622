import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SplitScreenWithImage from '../Layouts/SplitScreenWithImage';
import ProjectLogoDark from '@aseel/common-assets/assets/aseel-logo-dark.png';
import { InputController, PasswordInputController, Btn, BtnBase } from '@aseel/common-ui';

export default function SignInComponent({ onSignIn, signInBtnName, control, isSignUpShown, signUpPath, imageUrl }) {
    const { t } = useTranslation();

    return (
        <SplitScreenWithImage imgSrc={imageUrl}>
            <div>
                <img className="w-auto h-16" src={ProjectLogoDark} alt="Logo" />
                <Typography component="h2" className="mt-6 text-3xl font-extrabold text-gray-900">
                    {t('signIn.signInToYourAccount')}
                </Typography>
            </div>

            <div className="mt-8">
                <div className="mt-6">
                    <form className="space-y-4" onSubmit={onSignIn}>
                        <InputController control={control} name="email" label={t('formLabels.email')} />
                        <PasswordInputController control={control} name="password" label={t('formLabels.password')} />

                        <div className="flex items-center justify-between">
                            {/* <div className="flex items-center">
                                <CheckboxController
                                    label={t('signIn.rememberMe')}
                                    name="remember_me"
                                    control={control}
                                />
                            </div> */}

                            <Typography>
                                <Link component={RouterLink} to="/forgot-password" color="grey.800">
                                    {t('signIn.forgotYourPassword')}
                                </Link>
                            </Typography>
                        </div>

                        <Btn type="submit" name={signInBtnName}>
                            {t('signIn.signIn')}
                        </Btn>

                        {isSignUpShown ? (
                            <>
                                <div class="relative">
                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div class="w-full border-t border-solid border-gray-300"></div>
                                    </div>
                                    <div class="relative flex justify-center">
                                        <Typography component="span" className="px-2 text-sm text-gray-500 bg-white">
                                            {t('common.or')}
                                        </Typography>
                                    </div>
                                </div>
                                <BtnBase
                                    variant="outlined"
                                    component={RouterLink}
                                    to={signUpPath}
                                    type="submit"
                                    className="mt-2">
                                    {t('signIn.signUp')}
                                </BtnBase>
                            </>
                        ) : null}
                    </form>
                </div>
            </div>
        </SplitScreenWithImage>
    );
}
