import React from 'react';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MoneyIcon from '@mui/icons-material/Money';
import OverviewCard from '@aseel/common-components/src/Dashboard/Overview/OverviewCard';
import { useTranslation } from 'react-i18next';
import SectionLayout from '@aseel/common-components/src/Dashboard/SectionLayout';
import { useQuery } from 'react-query';
import { DASHBOARD_OVERVIEW } from '@aseel/constants/src/QueryKeys';
import { http } from 'Utils/Http/Http';
import OverviewSkeleton from '@aseel/common-components/src/Skeletons/Dashboard/OverviewSkeleton';

const styles = {
    iconStyle: {
        fontSize: '3rem',
    },
    danger: {
        color: '#ff3838',
    },
    success: {
        color: '#07f702',
    },
};

const Overview = () => {
    const { t } = useTranslation();

    const dashboardQuery = useQuery(DASHBOARD_OVERVIEW, () =>
        http.get('/dashboard/overview').then(({ data }) => data.data),
    );

    const cardsData = [
        {
            value: dashboardQuery.data?.listings_count,
            identifier: t('dashboard.funds'),
            icon: <HomeWorkIcon className={styles.iconStyle} />,
        },
        {
            value: dashboardQuery.data?.total_invested,
            identifier: t('dashboard.totalInvested'),
            icon: <LocalAtmIcon className={styles.iconStyle} />,
        },
        {
            value: dashboardQuery.data?.total_aum,
            identifier: t('dashboard.totalAUM'),
            icon: <MoneyIcon className={styles.iconStyle} />,
        },
    ];

    return (
        <SectionLayout
            sectionTitle={t('dashboard.overview')}
            isLoading={false}
            sectionContent={
                dashboardQuery.isLoading ? (
                    <OverviewSkeleton count={3} />
                ) : (
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {cardsData.map((data) => {
                            return <OverviewCard data={data} />;
                        })}
                    </div>
                )
            }
        />
    );
};

export default Overview;
