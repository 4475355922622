import { Empty } from '@aseel/common-ui';
import { Card, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const styles = {
    iconContainer: {
        backgroundColor: 'secondary.main',
    },
};

export default function TimelineTab({ updates }) {
    return (
        <Card>
            <CardContent>
                {updates ? (
                    <div className="flow-root p-5">
                        <ul className="-mb-8">
                            {updates.map((update, index) => (
                                <li key={index}>
                                    <div className="relative pb-8">
                                        {index !== updates.length - 1 ? (
                                            <span
                                                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                aria-hidden="true"
                                            />
                                        ) : null}
                                        <div className="relative flex space-x-3">
                                            <div>
                                                <Box
                                                    sx={styles.iconContainer}
                                                    component="span"
                                                    className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                                />
                                            </div>
                                            <div className="min-w-0 flex-1 flex justify-between items-start space-x-4">
                                                <div>
                                                    <Typography variant="h5">{update.title_en}</Typography>
                                                    <Typography variant="h5">{update.title_ar}</Typography>
                                                    <Typography variant="body2" component="time" dateTime={update.date}>
                                                        {update.date}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {update.description_en}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {update.description_ar}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <Empty />
                )}
            </CardContent>
        </Card>
    );
}
