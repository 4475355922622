import React from 'react';
import { http } from 'Utils/Http/Http';
import FundReplyForm from '@aseel/common-components/src/Funds/Discussions/Components/ReplyForm';

const replySubmitRequest = ({ isEdit, data, questionId, reply, fundId }) =>
    http.request({
        method: isEdit ? 'PUT' : 'POST',
        url: !isEdit
            ? `/listings/${fundId}/discussions/${questionId}/replies`
            : `/listings/${fundId}/discussions/${questionId}/replies/${reply.id}`,
        data,
    });

export default function ReplyForm(props) {
    return <FundReplyForm submitRequest={replySubmitRequest} {...props} />;
}
