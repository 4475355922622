import React from 'react';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent } from '@mui/material';
import { Btn, InputController, setProgress } from '@aseel/common-ui';
import { Yup } from '@aseel/common-utils';
import { useParams } from 'react-router';
import { http } from 'Utils/Http/Http';

const CREATE_PAYOUT_BTN = 'CREATE_PAYOUT_BTN';

const schema = Yup.object().shape({
    total: Yup.number().min(1).required(),
});

function CreatePayoutDialog({ isOpen, onClose, payoutsQuery, setIsOpen }) {
    const { t } = useTranslation();
    const { fundId } = useParams();
    const { control, handleSubmit, setError, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const handleFormSubmission = async (values) => {
        setProgress(CREATE_PAYOUT_BTN, true);

        try {
            await http.post(`/listings/${fundId}/payouts`, values);
            payoutsQuery.refetch();
            setIsOpen(false);
            reset({});
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CREATE_PAYOUT_BTN, false);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogContent>
                <form onSubmit={handleSubmit(handleFormSubmission)} className="space-y-10 py-5">
                    <InputController variant="standard" control={control} name="total" label={t('funds.totalAmount')} />
                    <div className="flex justify-end">
                        <Btn fullWidth={false} variant="text" onClick={onClose}>
                            {t('common.cancel')}
                        </Btn>
                        <Btn fullWidth={false} type="submit" name={CREATE_PAYOUT_BTN} variant="text">
                            {t('common.create')}
                        </Btn>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default CreatePayoutDialog;
