import { GuardedRoute } from '@aseel/common-utils';
import BasicLayout from 'Layouts/BasicLayout';
import EntityProfilePage from 'Pages/EntityProfile/EntityProfilePage';
import UpdateEntityPage from 'Pages/EntityProfile/UpdateEntityPage';
import RegisterPage from 'Pages/RegisterPage/RegisterPage';
import React from 'react';
import { Switch } from 'react-router-dom';
import GuestGuard from 'Utils/Guards/GuestGuard';
import AuthGuard from 'Utils/Guards/AuthGuard';
import ConfirmInvitationPage from 'Pages/RegisterPage/ConfirmInvitationPage';

function BasicLayoutEntry() {
    return (
        <BasicLayout>
            <Switch>
                <GuardedRoute guards={[GuestGuard]} exact path="/sign-up" component={RegisterPage} />
                <GuardedRoute guards={[GuestGuard]} exact path="/sign-up/invitations" component={ConfirmInvitationPage} />
                <GuardedRoute guards={[AuthGuard]} exact path="/entity/profile" component={EntityProfilePage} />
                <GuardedRoute guards={[AuthGuard]} exact path="/entity/profile/update" component={UpdateEntityPage} />
            </Switch>
        </BasicLayout>
    );
}

export default BasicLayoutEntry;
