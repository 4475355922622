import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import { DataGrid } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import { IconButton, Paper } from '@mui/material';
import { FUNDS } from '../../Constants/QueriesKeys';
import RefreshIcon from '@mui/icons-material/Refresh';
import { approvalStatusModalTypes } from '@aseel/common-components';
import { Link, useRouteMatch } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ApprovalStatusChip } from '@aseel/common-components';
import { ResourceListSkeleton, ResourcesList } from '@aseel/common-ui';
import FundActions from '@aseel/common-components/src/Funds/Components/FundActions';

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
    actionButtonFontSize: {
        fontSize: '1.35rem',
    },
};

// Component goes here
const FundsPage = () => {
    const { path } = useRouteMatch();
    const { t } = useTranslation();

    const [page, setPage] = useState(1);

    const fundsQuery = useQuery([FUNDS, { page }], () =>
        http.get(`/listings`, { params: { page } }).then(({ data }) => data),
    );

    const columns = [
        {
            field: 'id',
            headerName: t('funds.id'),
            minWidth: 10,
            editable: false,
        },
        {
            field: 'title',
            headerName: t('funds.title'),
            minWidth: 250,
            editable: false,
        },
        {
            field: 'target',
            headerName: t('funds.target'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'status',
            headerName: t('funds.status'),
            minWidth: 160,
            editable: false,
            renderCell: (row) => (
                <ApprovalStatusChip
                    status={row.row.review_status.value}
                    label={row.row.review_status.description}
                    modalType={approvalStatusModalTypes.general}
                />
            ),
        },
        {
            field: 'actions',
            headerName: t('funds.actions'),
            minWidth: 200,
            flex: 1,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex flex-col">
                        <FundActions fundId={row.id} containerClassName="grid grid-cols-4" />
                    </div>
                );
            },
        },
    ];

    if (fundsQuery.isLoading) {
        return <ResourceListSkeleton />;
    }

    return (
        <ResourcesList
            headerProps={{
                title: t('funds.funds'),
                EndSide: (
                    <div>
                        <IconButton onClick={fundsQuery.refetch} size="large">
                            <RefreshIcon />
                        </IconButton>
                        <IconButton component={Link} to={`${path}/create`} size="large">
                            <AddCircleIcon />
                        </IconButton>
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <div className={styles.tableSize}>
                        <Paper>
                            <DataGrid
                                rowHeight={100}
                                pagination
                                rowCount={fundsQuery.data?.meta.total}
                                paginationMode="server"
                                onPageChange={(newPage) => setPage(newPage)}
                                loading={fundsQuery.isFetching}
                                rows={fundsQuery.data?.data || []}
                                columns={columns}
                                pageSize={fundsQuery.data?.meta.per_page}
                            />
                        </Paper>
                    </div>
                </div>
            }
        />
    );
};

export default FundsPage;
