import React from 'react';
import { useTranslation } from 'react-i18next';
import { BtnBase, DatePickerController, IconBtn, InputController } from '@aseel/common-ui';
import { Typography } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useRootProps } from '../Utils/RootPropsContext';

// Component goes here
const TimelineTab = () => {
    const { t } = useTranslation();
    const { control } = useRootProps();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'timeline',
    });

    return (
        <div>
            <div className="flex justify-center my-7">
                <BtnBase fullWidth={false} onClick={() => append({})}>
                    {t('funds.newTimelineItem')}
                </BtnBase>
            </div>
            <div className="lg:px-10">
                <div className="space-y-5 divide-y divide-gray-400">
                    {fields.map((item, index) => (
                        <div key={item.id} className="space-y-2">
                            <div className="flex items-center space-x-3 mt-2">
                                <Typography variant="h6">
                                    {t('funds.timelineItem', { itemIndex: index + 1 })}
                                </Typography>
                                <div>
                                    <IconBtn onClick={() => remove(index)}>
                                        <DeleteForeverRoundedIcon fontSize="small" />
                                    </IconBtn>
                                </div>
                            </div>
                            <div className="space-y-3.5">
                                <InputController
                                    control={control}
                                    name={`timeline[${index}].title_en`}
                                    label={t('funds.title_en')}
                                />
                                <InputController
                                    control={control}
                                    name={`timeline[${index}].title_ar`}
                                    label={t('funds.title_ar')}
                                />

                                <DatePickerController
                                    control={control}
                                    name={`timeline[${index}].date`}
                                    label={t('funds.date')}
                                />

                                <InputController
                                    control={control}
                                    name={`timeline[${index}].description_en`}
                                    label={t('funds.description_en')}
                                    multiline
                                    rows={4}
                                />
                                <InputController
                                    control={control}
                                    name={`timeline[${index}].description_ar`}
                                    label={t('funds.description_ar')}
                                    multiline
                                    rows={4}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TimelineTab;
