import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Card, CardContent, Link, Typography } from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FUND_DISCUSSIONS } from '@aseel/constants/src/QueryKeys';
import DiscussionItem from './Components/DiscussionItem';
import { Pagination } from '@mui/material';
import { Empty, ResourcesList } from '@aseel/common-ui';

// Component goes here
export default function FundDiscussions({
    discussionsRequest,
    ReplyFormComponent,
    deleteReplyRequest,
    deleteDiscussionRequest,
}) {
    const { t } = useTranslation();
    const { fundId } = useParams();
    const [page, setPage] = useState(1);

    const discussionsQuery = useQuery([FUND_DISCUSSIONS, { fundId, page }], () => discussionsRequest({ page, fundId }));

    if (discussionsQuery.isLoading) {
        return '';
    }

    return (
        <div>
            <ResourcesList
                className="mt-3"
                BeforeHeader={
                    <Breadcrumbs className="mb-4">
                        <Link component={NavLink} to="/app/funds">
                            {t('funds.discussion.title')}
                        </Link>
                        <Link>#{fundId}</Link>
                        <Typography color="textPrimary">{t('funds.discussion.subtitle')}</Typography>
                    </Breadcrumbs>
                }
                Section={
                    discussionsQuery.data.data.length ? (
                        <>
                            <div className="space-y-4 mt-4">
                                {discussionsQuery.data.data.map((item) => {
                                    return (
                                        <DiscussionItem
                                            deleteDiscussionRequest={deleteDiscussionRequest}
                                            deleteReplyRequest={deleteReplyRequest}
                                            key={item.id}
                                            ReplyFormComponent={ReplyFormComponent}
                                            discussion={item}
                                            discussionsQuery={discussionsQuery}
                                            fundId={fundId}
                                        />
                                    );
                                })}
                            </div>

                            {discussionsQuery.data?.meta.last_page > 1 ? (
                                <div className="flex justify-center mt-4">
                                    <Pagination
                                        onChange={(event, page) => setPage(page)}
                                        color="primary"
                                        count={discussionsQuery.data?.meta.last_page}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <Card className="mt-4">
                            <CardContent>
                                <Empty>{t('funds.discussion.noRecord')}</Empty>
                            </CardContent>
                        </Card>
                    )
                }
            />
        </div>
    );
}
