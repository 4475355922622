import { Avatar, Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Empty } from '@aseel/common-ui';

export default function TeamTab({ teams }) {
    return (
        <Card>
            <CardContent>
                {teams ? (
                    <div className="space-y-4 p-5">
                        {teams.map((team) => (
                            <div>
                                <Typography variant="h6">{team.name_en}</Typography>
                                <Divider className="mb-3" />

                                <ul className="grid gap-4 lg:grid-cols-4">
                                    {team.members?.map((member) => (
                                        <li className="flex items-center">
                                            <Avatar className="me-2">
                                                <PersonRoundedIcon />
                                            </Avatar>
                                            <div>
                                                <Typography variant="body1" component="li">
                                                    {member.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    className="text-xs"
                                                    component="li"
                                                    color="textSecondary">
                                                    {member.job_title_en}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    className="text-xs"
                                                    component="li"
                                                    color="textSecondary">
                                                    {member.job_title_ar}
                                                </Typography>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Empty />
                )}
            </CardContent>
        </Card>
    );
}
