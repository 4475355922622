import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import getForLocale from '../../Utils/GetForLocale';
import useTranslation from '../../Hooks/UseTranslation';

const styles = {
    iconContainer: {
        backgroundColor: 'secondary.main',
    },
};

export default function TimelineTab({ updates }) {
    const { i18n } = useTranslation();

    const formatDate = (update) => {
        if (typeof update.date === 'string') {
            return update.date;
        } else {
            return update.date.format('YYYY-MM-DD');
        }
    };

    return updates === undefined ? null : (
        <Card>
            <CardContent>
                <div className="flow-root">
                    <ul className="-mb-8">
                        {updates?.map((update, index) => (
                            <li key={index}>
                                <div className="relative pb-8">
                                    {index !== updates.length - 1 ? (
                                        <span
                                            className="absolute top-4 start-4 -ml-px h-full w-0.5 bg-gray-200"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <div className="relative flex space-s-3">
                                        <div>
                                            <Typography
                                                className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                                sx={styles.iconContainer}></Typography>
                                        </div>
                                        <div className="min-w-0 flex-1 flex justify-between items-start space-x-4">
                                            <div>
                                                <Typography variant="h5">
                                                    {getForLocale(update, 'title', i18n.language)}
                                                </Typography>
                                                <Typography variant="body2" component="time">
                                                    {formatDate(update)}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {getForLocale(update, 'description', i18n.language)}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </CardContent>
        </Card>
    );
}
