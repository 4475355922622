import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ConfirmationDialog } from '@aseel/common-ui';
import { BtnBase } from '@aseel/common-ui';
import { Link } from '@mui/material';

export default function LogoutButton({ auth, isButton = true }) {
    const { t } = useTranslation();
    const history = useHistory();

    const handleLogout = () => {
        ConfirmationDialog({
            text: t('common.logoutWarning'),
        })
            .then(() => {
                return auth.logout();
            })
            .then(() => {
                return history.push('/sign-in');
            });
    };

    if (auth.user === null) {
        return null;
    }

    return isButton ? (
        <BtnBase fullWidth={false} variant="text" onClick={handleLogout}>
            {t('common.logout')}
        </BtnBase>
    ) : (
        <Link className="font-semibold no-underline" underline="none" onClick={handleLogout}>
            {t('common.logout')}
        </Link>
    );
}
