import useAuth from 'Hooks/UseAuth';
import { Redirect } from 'react-router-dom';

export default function AuthGuard(next) {
    const { user, entity } = useAuth();

    if (user && entity) {
        return next();
    }

    return <Redirect to="/sign-in" />;
}
