import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

export default function DualLocaleDisplayer({ text, contentClassName = 'p-3 space-y-2' }) {
    const [activeTab, setActiveTab] = useState('en');

    return (
        <>
            <Tabs
                value={activeTab}
                onChange={(e, value) => setActiveTab(value)}
                indicatorColor="primary"
                variant="fullWidth">
                <Tab label="English" value="en" />
                <Tab label="العربية" value="ar" />
            </Tabs>
            {activeTab === 'en' ? (
                <div className={clsx(['prose', contentClassName])} dangerouslySetInnerHTML={{ __html: text.en }}></div>
            ) : null}

            {activeTab === 'ar' ? (
                <div className={clsx(['prose', contentClassName])} dangerouslySetInnerHTML={{ __html: text.ar }}></div>
            ) : null}
        </>
    );
}
