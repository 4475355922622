import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputController, SelectController, DatePickerController, CheckboxController } from '@aseel/common-ui';
import {} from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useWatch } from 'react-hook-form';
import { Scope } from '@aseel/constants';
import {
    CITIES_STATIC_DATA,
    FUND_TYPES_STATIC_DATA,
    INVESTMENT_TYPE_STATIC_DATA,
    BANK_ACCOUNTS,
} from '@aseel/constants/src/QueryKeys';

const BankAccountSelectFieldsForAdmin = ({ control, authorizedEntitiesQuery }) => {
    const { t } = useTranslation();

    const authorizedEntityId = useWatch({
        control,
        name: 'listing.authorized_entity_id',
    });

    return (
        <>
            <SelectController
                control={control}
                name="listing.authorized_entity_id"
                label={t('funds.authorizedEntity')}
                options={authorizedEntitiesQuery?.data || []}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                menuPortalTarget={document.body}
            />

            <SelectController
                optional
                control={control}
                name="listing.bank_account_id"
                label={t('funds.bankAccount')}
                options={
                    authorizedEntityId
                        ? (authorizedEntitiesQuery.data || []).find((entity) => entity.id == authorizedEntityId)
                              ?.bank_accounts || []
                        : []
                }
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                menuPortalTarget={document.body}
            />
        </>
    );
};

const BankAccountSelectFieldsForAuthorizedEntity = ({ control }) => {
    const { t } = useTranslation();

    const bankAccountsQuery = useQuery([BANK_ACCOUNTS, { type: 'Approved' }], () =>
        http.get(`/bank-accounts/approved`).then((data) => data.data.data),
    );

    return (
        <SelectController
            optional
            control={control}
            name="listing.bank_account_id"
            label={t('funds.bankAccount')}
            options={bankAccountsQuery.data || []}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            menuPortalTarget={document.body}
            className="col-span-12"
        />
    );
};

// Component goes here
const FundBasicDetailInputs = ({ control, scope, isInvestingStarted, authorizedEntitiesQuery }) => {
    const citiesQuery = useQuery(CITIES_STATIC_DATA, () =>
        http.get(`/cities`, { baseURL: `${process.env.REACT_APP_BASE_URL}/api` }).then(({ data }) => data.data),
    );

    const fundTypesQuery = useQuery(FUND_TYPES_STATIC_DATA, () =>
        http.get(`/property-types`).then(({ data }) => data.data),
    );

    const investmentTypesQuery = useQuery(INVESTMENT_TYPE_STATIC_DATA, () =>
        http
            .get(`/investment-types`, { baseURL: `${process.env.REACT_APP_BASE_URL}/api` })
            .then(({ data }) => data.data),
    );

    const { t } = useTranslation();

    return (
        <>
            <div className="py-3 space-y-5">
                <div className="grid gap-5 md:grid-cols-2">
                    <InputController control={control} name="listing.title_en" label={t('funds.title_en')} />
                    <InputController control={control} name="listing.title_ar" label={t('funds.title_ar')} />
                </div>
                <div className="grid gap-5 md:grid-cols-3">
                    <SelectController
                        control={control}
                        name="listing.city_id"
                        label={t('funds.city_id')}
                        options={citiesQuery && citiesQuery.data ? citiesQuery.data : []}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                    />
                    <SelectController
                        control={control}
                        name="listing.investment_type"
                        label={t('funds.type_id')}
                        options={investmentTypesQuery.data || []}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.name}
                    />
                    <SelectController
                        control={control}
                        name="listing.type_id"
                        label={t('funds.investment_type')}
                        options={fundTypesQuery.data || []}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                    />
                    <InputController
                        control={control}
                        name="listing.target"
                        label={t('funds.target')}
                        disabled={isInvestingStarted}
                    />
                    <InputController
                        control={control}
                        name="listing.total_shares"
                        label={t('funds.total_shares')}
                        disabled={isInvestingStarted}
                    />
                    <InputController
                        control={control}
                        name="listing.min_inv_share"
                        label={t('funds.min_inv_share')}
                        disabled={isInvestingStarted}
                    />

                    {/* <InputController
                        control={control}
                        name="listing.reserved_shares"
                        label={t('funds.reserved_shares')}
                    /> */}
                    {/* <InputController control={control} name="listing.gross_yield" label={t('funds.gross_yield')} />
                    <InputController
                        control={control}
                        name="listing.dividend_yield"
                        label={t('funds.dividend_yield')}
                    />
                    <InputController control={control} name="listing.rent_amount" label={t('funds.rent_amount')} /> */}
                </div>
                
                <div className="grid gap-5 md:grid-cols-2">
                    <InputController
                        control={control}
                        name="listing.max_inv_share"
                        label={t('funds.max_inv_share')}
                        disabled={isInvestingStarted}
                    />
                    <DatePickerController control={control} name="listing.deadline" label={t('funds.deadline')} />
                </div>

                <div className="grid gap-5 md:grid-cols-2">
                    {scope === Scope.admin ? (
                        <BankAccountSelectFieldsForAdmin
                            control={control}
                            authorizedEntitiesQuery={authorizedEntitiesQuery}
                        />
                    ) : (
                        <BankAccountSelectFieldsForAuthorizedEntity control={control} />
                    )}
                </div>
            </div>

            <div className="mt-2">
                <CheckboxController control={control} name="listing.is_visible" label={t('funds.is_visible')} />
                <CheckboxController control={control} name="listing.is_closed" label={t('funds.is_closed')} />
            </div>
        </>
    );
};

export default FundBasicDetailInputs;
