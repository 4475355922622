import React from 'react';
import clsx from 'clsx';
import { Popover } from '@headlessui/react';
import SidebarForMobile from './SidebarForMobile';
import logo from '@aseel/common-assets/assets/aseel-logo-dark.png';
import LogoutButton from '@aseel/common-components/src/Auth/LogoutButton';

export default function Navbar({ links, auth }) {
    return (
        <Popover
            as="header"
            className={({ open }) =>
                clsx(
                    open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
                    'bg-white shadow-sm lg:static lg:overflow-y-visible',
                )
            }>
            {({ open }) => (
                <>
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="relative flex justify-between lg:py-3">
                            <div className="flex items-center justify-between w-full lg:static xl:col-span-2">
                                <div className="flex items-center flex-shrink-0 my-2 ml-0 lg:my-0 lg:ml-4">
                                    <a href="#">
                                        <img className="block w-auto h-14" src={logo} alt="Aseel" />
                                    </a>
                                </div>
                                <div className="hidden lg:block">
                                    <LogoutButton auth={auth} isButton />
                                </div>
                                <div className="absolute right-0 lg:hidden top-3">
                                    <SidebarForMobile links={links} auth={auth} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                        <div className="max-w-3xl px-2 pt-2 pb-3 mx-auto space-y-1 sm:px-4">
                            {links.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    aria-current={item.current ? 'page' : undefined}
                                    className={clsx(
                                        item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                                        'block rounded-md py-2 px-3 text-base font-medium',
                                    )}>
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
}
