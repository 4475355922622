import React, { useState } from 'react';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { Breadcrumbs, Card, CardContent, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { isAxiosError } from '@aseel/http';
import { FUND_PAYOUT_INVESTORS } from 'Constants/QueriesKeys';
import Paper from '@mui/material/Paper';
import PayoutDetailsSkeleton from '../Skeletons/PayoutDetailsSkeleton';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateToast } from '@aseel/common-utils';
import { Btn, ResourcesList, BtnBase, DataGrid, InputController, ConfirmationDialog } from '@aseel/common-ui';
import { http } from 'Utils/Http/Http';
import { ActiveChip } from '@aseel/common-components';



let schema = Yup.lazy((obj) => {
    const objectShape = {};

    Object.keys(obj).forEach((key) => {
        objectShape[key] = Yup.number().min(0).required();
    });

    return Yup.object().shape(objectShape);
});

function PayoutDetailsPage() {
    const { t } = useTranslation();
    const { fundId, payoutId } = useParams();
    const [selectedRows, setSelectedRows] = useState([]);

    const form = useForm({
        resolver: yupResolver(schema),
    });

    const payoutInvestorsQuery = useQuery(
        [FUND_PAYOUT_INVESTORS, { payoutId }],
        () => http.get(`/listings/${fundId}/payouts/${payoutId}`).then((response) => response.data.data),
        {
            onSuccess: (data) => {
                const tranformedAmounts = {};

                data.investors.forEach((investor) => {
                    tranformedAmounts[investor.id] = investor.expected;
                });

                form.reset({ ...tranformedAmounts });
            },
        },
    );

    const handlePayoutPay = (row) => {
        let toastId = null;
        ConfirmationDialog({
            text: t('funds.payoutsDetails.actionForTransferringMoney'),
        })
            .then(() => {
                toastId = toast.loading(t('messages.payoutBeingSent'), {
                    updateId: 1,
                });
                return http.post(`/listings/${fundId}/payouts/${payoutId}/pay`, {
                    investors: [
                        {
                            id: row.id,
                            amount: row.expected,
                        },
                    ],
                });
            })
            .then(() => {
                updateToast(toastId, {
                    type: 'success',
                    render: t('messages.payoutBeingSentRequestSent'),
                });
            })
            .catch((error) => {
                if (isAxiosError(error) && toastId) {
                    updateToast(toastId, {
                        type: 'error',
                        render: t('messages.payoutBeingSentRequest'),
                    });
                }
            });
    };

    const handleBulkPay = async () => {
        const isValid = form.trigger();
        if (!isValid) {
            return;
        }

        const amounts = form.getValues();
        const requestBody = selectedRows.map((rowId) => ({
            id: rowId,
            amount: amounts[rowId],
        }));

        let toastId = null;
        ConfirmationDialog({
            text: t('funds.payoutsDetails.actionForTransferringMoney'),
        })
            .then(() => {
                toastId = toast.loading(t('messages.payoutBeingSent'), {
                    updateId: 1,
                });
                return http.post(`/listings/${fundId}/payouts/${payoutId}/pay`, {
                    investors: requestBody,
                });
            })
            .then(() => {
                updateToast(toastId, {
                    type: 'success',
                    render: t('messages.payoutBeingSentRequestSent'),
                });
            })
            .catch((error) => {
                if (isAxiosError(error) && toastId) {
                    updateToast(toastId, {
                        type: 'error',
                        render: t('messages.payoutBeingSentRequest'),
                    });
                }
            });
    };

    const payoutDetailsColumns = [
        {
            field: 'name',
            headerName: t('funds.payoutsDetails.investor'),
            flex: 1,
        },
        {
            field: 'shares',
            headerName: t('funds.payoutsDetails.ownedUnits'),
            flex: 1,
        },
        {
            field: 'expected',
            headerName: t('funds.payoutsDetails.expectedPayment'),
            flex: 1,
            renderCell: (row) => <InputController variant="standard" control={form.control} name={`${row.row.id}`} />,
        },
        {
            field: 'paid',
            headerName: t('funds.payoutsDetails.paid'),
            flex: 1,
        },
       
        {
            field: 'actions',
            headerName: t('funds.payoutsDetails.actions'),
            flex: 1,
            renderCell: (row) => (
                <div>
                    <BtnBase color="green" fullWidth={false} size="small" onClick={() => handlePayoutPay(row.row)}>
                        {t('funds.payoutsDetails.pay')}
                    </BtnBase>
                </div>
            ),
        },
    ];

    const payoutInvestorsRows = payoutInvestorsQuery.data?.investors;

    if (payoutInvestorsQuery.isLoading) {
        return <PayoutDetailsSkeleton hasBreadcrumbs={true} />;
    }

    return (
        <div>
            <ResourcesList
                hideDivider={true}
                BeforeHeader={
                    <div>
                        <Breadcrumbs className="mb-5">
                            <Link component={NavLink} to={`/app/funds`}>
                                {t('funds.payoutsDetails.funds')}
                            </Link>
                            <Link component={NavLink} to={`/app/funds/${fundId}`}>
                                #{fundId}
                            </Link>
                            <Link component={NavLink} to={`/app/funds/${fundId}/payouts`}>
                                {t('funds.payoutsDetails.payouts')}
                            </Link>
                            <Typography color="textPrimary"> #{payoutId}</Typography>
                        </Breadcrumbs>
                        <Card>
                            <CardContent>
                                <div className="space-y-1.5">
                                    <Typography variant="h5">{payoutInvestorsQuery.data?.listing.title}</Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        {t('funds.payoutsDetails.id')}# {payoutId}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        {t('funds.payoutsDetails.payoutTotal', {
                                            payoutTotal: `${payoutInvestorsQuery.data?.total}`,
                                        })}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        {t('funds.payoutsDetails.date', {
                                            date: `${payoutInvestorsQuery.data?.payout_date}`,
                                        })}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        {t('funds.payoutsDetails.unitPrice', {
                                            sharePrice: `${payoutInvestorsQuery.data?.listing.share_price}`,
                                        })}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body1">
                                        {t('funds.payoutsDetails.totalUnits', {
                                            totalUnits: `${payoutInvestorsQuery.data?.listing.total_shares}`,
                                        })}
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                }
                headerProps={{
                    EndSide: (
                        <div className="mt-5">
                            <Btn fullWidth={false} variant="outlined" size="small" onClick={handleBulkPay}>
                                {t('funds.payoutsDetails.pay')}
                            </Btn>
                        </div>
                    ),
                }}
                Section={
                    <Paper className="mt-4">
                        <DataGrid
                            columns={payoutDetailsColumns}
                            rows={payoutInvestorsRows}
                            checkboxSelection
                            disableSelectionOnClick
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectedRows(newSelectionModel);
                            }}
                        />
                    </Paper>
                }
            />
        </div>
    );
}

export default PayoutDetailsPage;
