import React from 'react';
import Funds from '@aseel/common-components/src/Dashboard/Funds';
import { http } from 'Utils/Http/Http';
import Overview from './Overview/Overview';

const fundRequest = () => http.get('/dashboard/listings').then(({ data }) => data.data);

const DashboardPage = () => {
    return (
        <div className="space-y-7 px-4 sm:px-0">
            <Overview />
            {/* <Conversion /> */}
            <Funds fundRequest={fundRequest} />
        </div>
    );
};

export default DashboardPage;
