import useAuth from 'Hooks/UseAuth';
import { Redirect } from 'react-router-dom';

export default function ApprovedEntityGuard(next) {
    const { entity } = useAuth();

    if (entity.review_status.value === 3) {
        return next();
    }

    return <Redirect to="/entity/profile" />;
}
