import React, { useMemo } from 'react';
import FundPreview from './FundPreview';
import { Dialog, DialogContent } from '@mui/material';
import { i18nClonedContext } from './Contexts/I18nClonedContext';
import { i18n } from '@aseel/common-utils';

export default function FundPreviewDialog({ isOpen, onClose, fundData, authorizedEntityName, isEdit }) {
    const i18nCloned = useMemo(() => i18n.cloneInstance(), [i18n]);


    return (
        <i18nClonedContext.Provider value={i18nCloned}>
            <Dialog open={isOpen} onClose={onClose} fullScreen>
                <DialogContent>
                    <FundPreview onClose={onClose} fundData={fundData} authorizedEntityName={authorizedEntityName} isEdit={isEdit} />
                </DialogContent>
            </Dialog>
        </i18nClonedContext.Provider>
    );
}
