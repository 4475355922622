import { Typography } from '@mui/material';
import React from 'react';

function TitleWithText({ title, text }) {
    return (
        <div>
            <Typography variant="body2" color="textSecondary">{title}</Typography>
            <Typography variant="body1">{text}</Typography>
        </div>
    );
}

export default TitleWithText;
