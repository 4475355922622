import { BtnBase, IconBtn, InputController } from '@aseel/common-ui';
import { Typography } from '@mui/material';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import MemberAddEditForm from './MemberAddEditForm';
import { useRootProps } from '../../Utils/RootPropsContext';

// Component goes here
const TeamsTab = () => {
    const { t } = useTranslation();
    const { control } = useRootProps();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'team',
    });

    return (
        <>
            <div className="flex justify-center my-7">
                <BtnBase fullWidth={false} onClick={() => append({})}>
                    {t('funds.newTeam')}
                </BtnBase>
            </div>
            <div className="divide-y divide-gray-400">
                {fields.map(({ id }, index) => {
                    return (
                        <div key={id}>
                            <div className="flex items-center space-x-3 mt-5 mb-3">
                                <Typography variant="h6">{t('funds.team')}</Typography>
                                <div>
                                    <IconBtn onClick={() => remove(index)}>
                                        <DeleteForeverRoundedIcon fontSize="small" />
                                    </IconBtn>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                <InputController
                                    name={`team[${index}].name_en`}
                                    control={control}
                                    label={t('funds.name_en')}
                                />
                                <InputController
                                    name={`team[${index}].name_ar`}
                                    control={control}
                                    label={t('funds.name_ar')}
                                />
                            </div>
                            <MemberAddEditForm teamIndex={index} {...{ control }} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default TeamsTab;
