import React from 'react';
import { Card, CardContent, Chip, Container, Divider, Typography } from '@mui/material';
import useAuth from 'Hooks/UseAuth';
import { BtnBase } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { Link } from 'react-router-dom';

const Item = ({ label, description, className }) => {
    return (
        <div className={className}>
            <Typography variant="body2" color="textSecondary" component="h1" className="font-medium">
                {label}
            </Typography>
            {typeof description === 'string' ? (
                <Typography variant="body1" component="h1" className="font-medium">
                    {description}
                </Typography>
            ) : (
                description
            )}
        </div>
    );
};

export default function EntityProfilePage() {
    const { entity } = useAuth();
    const { t } = useTranslation();

    return (
        <div className="flex flex-col flex-grow justify-center items-center">
            <Container maxWidth="sm">
                <Card>
                    <div className="p-3">
                        <Typography variant="h6" component="h1">
                            {t('entity.companyProfile')}
                        </Typography>
                    </div>
                    <Divider />
                    <CardContent>
                        <div className="grid grid-cols-3 gap-4">
                            <Item label={t('register.companyName')} description={entity.name} />
                            <Item label={t('register.crNumber')} description={entity.cr_number} />
                            <Item
                                label={t('entity.status')}
                                description={<Chip size="small" label={entity.review_status.description} />}
                            />
                            <Item
                                className="col-span-3"
                                label={t('register.description')}
                                description={entity.description}
                            />
                            <Item
                                className="col-span-3"
                                label={t('register.cr')}
                                description={
                                    <div className="space-y-2 mt-1">
                                        {entity.cr.map((file) => (
                                            <BtnBase
                                                startIcon={<DescriptionRoundedIcon />}
                                                fullWidth={false}
                                                variant="outlined"
                                                component="a"
                                                href={file.url}
                                                download>
                                                {file.name}
                                            </BtnBase>
                                        ))}
                                    </div>
                                }
                            />

                            <Item
                                className="col-span-3"
                                label={t('register.cmaLicense')}
                                description={
                                    <div className="space-y-2 mt-1">
                                        {entity.cma_license.map((file) => (
                                            <BtnBase
                                                startIcon={<DescriptionRoundedIcon />}
                                                fullWidth={false}
                                                variant="outlined"
                                                component="a"
                                                href={file.url}
                                                download>
                                                {file.name}
                                            </BtnBase>
                                        ))}
                                    </div>
                                }
                            />
                        </div>
                    </CardContent>
                    {entity.review_status.value === 2 ? null : (
                        <>
                            <Divider />
                            <div className="p-3">
                                <div className="flex justify-end">
                                    <BtnBase
                                        component={Link}
                                        to="/entity/profile/update"
                                        size="small"
                                        color="gold"
                                        fullWidth={false}>
                                        {t('entity.updateProfile')}
                                    </BtnBase>
                                </div>
                            </div>
                        </>
                    )}
                </Card>
            </Container>
        </div>
    );
}
