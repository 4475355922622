import { Card } from '@mui/material';
import React from 'react';
import DualLocaleDisplayer from '../../Components/DualLocaleDisplayer';

export default function TermsAndConditions({ text }) {
    return (
        <Card className="mt-2">
            <DualLocaleDisplayer text={text} />
        </Card>
    );
}
