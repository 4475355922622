import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Breadcrumbs, IconButton, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ReportInfoDialogComponent from './Components/ReportInfoDialogComponent';
import { ResourcesList, BtnBase, DataGrid } from '@aseel/common-ui';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { FUND_REPORTS } from 'Constants/QueriesKeys';
import FundReportsSkeleton from './Skeletons/FundReportsSkeleton';
import Link from '@mui/material/Link';
import { NavLink, useHistory } from 'react-router-dom';

export default function FundReportsComponent({ reportsRequest, fundId }) {
    const { t } = useTranslation();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [reportInfo, setReportInfo] = useState({
        show: false,
        info: null,
    });

    const reportsQuery = useQuery([FUND_REPORTS, { fundId, page }], () => reportsRequest({ fundId, page }));

    if (reportsQuery.isLoading) {
        return <FundReportsSkeleton />;
    }

    const handleCloseReportInfo = () => {
        setReportInfo({
            info: null,
            show: false,
        });
    };

    const handleOpenReportInfo = (row) => {
        setReportInfo({
            show: true,
            info: row,
        });
    };

    const reportsColumns = [
        {
            field: 'id',
            headerName: t('funds.reports.id'),
            editable: false,
        },
        {
            field: 'title',
            headerName: t('funds.reports.title'),
            editable: false,
            flex: 1,
        },
        {
            field: 'actions',
            headerName: t('funds.reports.action'),
            editable: false,
            minWidth: 150,
            renderCell: (row) => (
                <div>
                    <IconButton onClick={() => handleOpenReportInfo(row.row)} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => history.push(`/app/funds/${fundId}/reports/${row.row.id}`)} size="large">
                        <EditRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <div>
            <ResourcesList
                BeforeHeader={
                    <Breadcrumbs className="mb-5">
                        <Link component={NavLink} to={`/app/funds`}>
                            {t('funds.funds')}
                        </Link>
                        <Link component={NavLink} to={`/app/funds/${fundId}`}>
                            #{fundId}
                        </Link>
                        <Typography color="textPrimary"> {t('funds.reports.listingReports')}</Typography>
                    </Breadcrumbs>
                }
                headerProps={{
                    title: t('funds.reports.reports'),
                    EndSide: (
                        <BtnBase fullWidth={false} onClick={() => history.push(`/app/funds/${fundId}/reports/create`)}>
                            {t('common.create')}
                        </BtnBase>
                    ),
                }}
                Section={
                    <div className="mt-8">
                        <div>
                            <Paper>
                                <DataGrid
                                    columns={reportsColumns}
                                    rows={reportsQuery.data?.data}
                                    page={page}
                                    pageSize={reportsQuery.data?.meta.per_page}
                                    rowCount={reportsQuery.data?.meta.total}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    paginationMode="server"
                                />
                            </Paper>
                        </div>
                    </div>
                }
            />
            <ReportInfoDialogComponent
                isOpen={reportInfo.show}
                onClose={handleCloseReportInfo}
                reportInfo={reportInfo.info}
            />
        </div>
    );
}
