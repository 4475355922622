import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@aseel/common-ui';

export default function WalletTransactionsTable({ transactionQuery, page, setPage }) {
    const { t } = useTranslation();

    const columns = [
        {
            field: 'created_at',
            headerName: t('funds.iban.date'),
            minWidth: 250,
            editable: false,
        },
        {
            field: 'description',
            headerName: t('funds.iban.description'),
            minWidth: 300,
            editable: false,
        },
        {
            field: 'credit',
            headerName: t('funds.iban.credit'),
            minWidth: 250,
            editable: false,
        },
        {
            field: 'debit',
            headerName: t('funds.iban.debit'),
            editable: false,
        },
    ];

    return (
        <DataGrid
            pagination
            page={page}
            columns={columns}
            paginationMode="server"
            loading={transactionQuery.isLoading}
            rows={transactionQuery.data?.data || []}
            rowCount={transactionQuery?.data?.meta.total}
            pageSize={transactionQuery.data?.meta.per_page}
            onPageChange={(newPage) => setPage(newPage)}
        />
    );
}
