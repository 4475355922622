import { Card, CardContent, Paper, Tabs, Tab, Typography } from '@mui/material';
import React from 'react';
import TitleWithText from '../../Funds/Components/TitleWithText';
import { Skeleton } from '@mui/material';

function FundInformation({ informationContainerClassName }) {
    return (
        <div className="space-y-5 mb-10">
            <Card>
                <CardContent>
                    <div className="p-5">
                        <div className="space-y-5">
                            <div>
                                <Typography variant="h6">
                                    <Skeleton className="md:w-1/4 w-1/2" />
                                </Typography>
                                <Typography variant="h6">
                                    <Skeleton className="md:w-1/4  w-1/2" />
                                </Typography>
                            </div>
                            <div className="flex space-x-5">
                                <Skeleton className="md:w-1/12 w-2/12" />
                                <Skeleton className="md:w-1/12 w-2/12" />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <div className={informationContainerClassName}>
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/4 w-9/12" />}
                        />
                    </div>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <div className="p-5">
                        <Typography variant="h6">
                            <Skeleton className="md:w-2/12 w-1/4" />
                        </Typography>
                        <div className="space-y-3 mt-5">
                            <div className="flex justify-between items-center">
                                <Skeleton className="w-1/2 h-5" />
                                <Skeleton className="w-6 h-10 rounded-full" />
                            </div>
                            <div className="flex justify-between items-center">
                                <Skeleton className="w-1/2 h-5" />
                                <Skeleton className="w-6 h-10 rounded-full" />
                            </div>
                            <div className="flex justify-between items-center">
                                <Skeleton className="w-1/2 h-5" />
                                <Skeleton className="w-6 h-10 rounded-full" />
                            </div>
                            <div className="flex justify-between items-center">
                                <Skeleton className="w-1/2 h-5 " />
                                <Skeleton className="w-6 h-10 rounded-full" />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Paper className="mb-3">
                <Tabs
                    onChange={(event, value) => setCurrentTab(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    {Array(6)
                        .fill(0)
                        .map(() => (
                            <Tab label={<Skeleton className="w-full" />} />
                        ))}
                </Tabs>
            </Paper>
            <Card>
                <Skeleton height="500px" variant="rectangular" />
            </Card>
        </div>
    );
}

export default FundInformation;
