import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Card, CardContent, Divider, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconBtnBase } from '@aseel/common-ui';
import { ConfirmationDialog } from '@aseel/common-ui';
import { toast } from 'react-toastify';
import { updateToast } from '@aseel/common-utils';
import { isAxiosError } from '@aseel/http';

// Component goes here
export default function DiscussionItem({
    fundId,
    discussion,
    discussionsQuery,
    ReplyFormComponent,
    deleteReplyRequest,
    deleteDiscussionRequest,
}) {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isFormShown, setIsFormShown] = useState(false);
    const [isEditFormShown, setIsEditFormShown] = useState(false);
    const [replyIndex, setReplyIndex] = useState(null);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleToggleForm = () => {
        setIsFormShown(!isFormShown);
    };

    const handleToggleEditForm = () => {
        setIsEditFormShown(!isEditFormShown);
    };

    const handleEditReply = (item, index) => {
        setReplyIndex(index);
        setIsEditFormShown(true);
    };

    const deleteReply = (fundId, discussionId, replyId) => {
        let toastId = null;
        ConfirmationDialog({
            text: t('funds.discussion.deleteReplyText', { replyId: replyId }),
            title: t('funds.discussion.deleteReplyTitle', { replyId: replyId }),
        })
            .then(() => {
                toastId = toast.loading(t('funds.discussion.requestToDeleteReplyIsSent', { replyId: replyId }));
                return deleteReplyRequest({ fundId, discussionId, replyId });
            })
            .then(() => {
                updateToast(toastId, {
                    type: 'success',
                    render: t('funds.discussion.replyHasBeenDeletedSuccessfully', { replyId: replyId }),
                });
                return discussionsQuery.refetch();
            })
            .catch((error) => {
                if (isAxiosError(error) && toastId) {
                    updateToast(toastId, {
                        type: 'error',
                        render: t('funds.discussion.anErrorOccurredWhileDeletingReply', { replyId: replyId }),
                    });
                }
            });
    };

    const deleteDiscussion = (fundId, discussionId) => {
        let toastId = null;
        ConfirmationDialog({
            text: t('funds.discussion.deleteDiscussionText', { discussionId: discussionId }),
            title: t('funds.discussion.deleteDiscussionTitle', { discussionId: discussionId }),
        })
            .then(() => {
                toastId = toast.loading(
                    t('funds.discussion.requestToDeleteDiscussionIsSent', { discussionId: discussionId }),
                );
                return deleteDiscussionRequest({ fundId, discussionId });
            })
            .then(() => {
                updateToast(toastId, {
                    type: 'success',
                    render: t('funds.discussion.discussionHasBeenDeletedSuccessfully', { discussionId: discussionId }),
                });
                return discussionsQuery.refetch();
            })
            .catch((error) => {
                if (isAxiosError(error) && toastId) {
                    updateToast(toastId, {
                        type: 'error',
                        render: t('funds.discussion.anErrorOccurredWhileDeletingDiscussion', {
                            discussionId: discussionId,
                        }),
                    });
                }
            });
    };

    return (
        <Card className="relative">
            <CardContent>
                <div className="flex items-center">
                    <div className="w-full">
                        <Typography variant="body1" component="p">
                            {discussion.text}
                        </Typography>
                        <Typography variant="caption">
                            {discussion.user.first_name} {discussion.user.last_name} ({discussion.user.email})
                        </Typography>
                    </div>
                    <div className="flex justify-end w-full">
                        <IconBtnBase size="medium" onClick={() => deleteDiscussion(fundId, discussion.id)}>
                            <DeleteIcon fontSize="small" />
                        </IconBtnBase>
                    </div>
                </div>

                <div className="flex space-s-2 items-center mt-3 mb-1.5">
                    <Typography variant="h6">{t('funds.discussion.replies')}</Typography>
                    <Tooltip title={t('funds.discussion.newReply')}>
                        <AddCircleOutlineIcon className="cursor-pointer" onClick={handleToggleForm} />
                    </Tooltip>
                </div>

                {isFormShown ? (
                    <div>
                        <ReplyFormComponent
                            questionId={discussion.id}
                            discussionsQuery={discussionsQuery}
                            onToggleForm={handleToggleForm}
                            fundId={fundId}
                        />
                    </div>
                ) : null}

                {discussion.replies.length ? (
                    <Typography variant="body2" className="cursor-pointer" component="p" onClick={handleExpandClick}>
                        {isExpanded ? t('funds.discussion.hideReplies') : t('funds.discussion.showReplies')}
                    </Typography>
                ) : !isFormShown ? (
                    <div>
                        <Typography color="textSecondary">{t('funds.discussion.noRepliesSent')}</Typography>
                    </div>
                ) : null}

                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        {discussion.replies.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    {replyIndex === index && isEditFormShown ? (
                                        <ReplyFormComponent
                                            reply={item}
                                            isEdit
                                            questionId={discussion.id}
                                            discussionsQuery={discussionsQuery}
                                            onToggleForm={handleToggleEditForm}
                                            fundId={fundId}
                                        />
                                    ) : (
                                        <div>
                                            <Typography variant="body1">{item.text}</Typography>
                                            <div className="flex items-center justify-between mt-2">
                                                <div item className="flex flex-col space-y-0.5">
                                                    <Typography variant="caption">
                                                        {item.user.first_name} {item.user.last_name} (
                                                        {item.user.email})
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {t('funds.discussion.respondent', {
                                                            replierInfo: `${item.replier_name}  (${item.replier_role})`,
                                                        })}
                                                    </Typography>
                                                    <Typography variant="caption">{item.created_at}</Typography>
                                                </div>
                                                <div className="flex">
                                                    {item.can_edit ? (
                                                        <>
                                                            <IconBtnBase
                                                                size="medium"
                                                                onClick={() => handleEditReply(item, index)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconBtnBase>
                                                            <IconBtnBase
                                                                size="medium"
                                                                onClick={() =>
                                                                    deleteReply(fundId, discussion.id, item.id)
                                                                }>
                                                                <DeleteIcon fontSize="small" />
                                                            </IconBtnBase>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <Divider
                                        variant="fullWidth"
                                        component="hr"
                                        orientation="horizontal"
                                        style={{ width: '100%', margin: '20px 0px 20px' }}
                                    />
                                </div>
                            );
                        })}
                    </CardContent>
                </Collapse>
            </CardContent>
        </Card>
    );
}
