import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditorEnAr, InputController, UploaderController } from '@aseel/common-ui';
import { useRootProps } from '../Utils/RootPropsContext';

// Component goes here
const LocationTab = () => {
    const { t } = useTranslation();
    const { control } = useRootProps();

    return (
        <div className="space-y-4">
            <UploaderController control={control} name="location.files" label={t('funds.uploadFiles')}  />
            <InputController
                control={control}
                name="location.gmap_url"
                label={t('funds.googleMapUrl')}
                helperText={t('funds.pleaseCopyTheLocationURLFromGoogleMapsHere')}
            />

            <EditorEnAr control={control} arName="location.description_ar" enName="location.description_en" />
        </div>
    );
};

export default LocationTab;
