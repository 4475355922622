import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export default function WysiwygViewer({ text }) {
    const theme = useTheme();

    return (
        <Box
            sx={{ fontFamily: theme.typography.fontFamily }}
            className={theme.direction === 'rtl' ? 'prose prose-rtl' : 'prose'}
            dangerouslySetInnerHTML={{ __html: text }}></Box>
    );
}
