export const USERS = 'USERS';
export const FUNDS = 'FUNDS';
export const CITIES_STATIC_DATA = 'CITIES_STATIC_DATA';
export const FUND_TYPES_STATIC_DATA = 'FUND_TYPES_STATIC_DATA';
export const INVESTMENT_TYPE_STATIC_DATA = 'INVESTMENT_TYPE_STATIC_DATA';
export const AUTHORIZED_BANK_ACCOUNT = 'AUTHORIZED_BANK_ACCOUNT';
export const FUND_DISCUSSIONS = 'FUND_DISCUSSIONS';
export const FUND_IBAN_WALLETS = 'FUND_IBAN_WALLETS';
export const FUND_IBAN_BALANCE = 'FUND_IBAN_BALANCE';
export const FUND_IBAN_TRANSACTIONS = 'FUND_IBAN_TRANSACTIONS';
export const FUND_IBAN_INVESTORS = 'FUND_IBAN_INVESTORS';
export const FUND_PAYOUTS = 'FUND_PAYOUTS';
export const FUND_PAYOUT_INVESTORS = 'FUND_PAYOUT_INVESTORS';
export const FUND_REPORTS = 'FUND_REPORTS';
export const BANK_ACCOUNTS = 'BANK_ACCOUNTS';
export const FUND_VALUATIONS = 'FUND_VALUATIONS';
export const PAGE_BANK_ACCOUNTS = 'PAGE_BANK_ACCOUNTS';
export const BANKS = 'BANKS';
export const FUND_REVIEWS = 'FUND_REVIEWS';
export const AUTHORIZED_ENTITIES = 'AUTHORIZED_ENTITIES';


