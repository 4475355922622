import { useTranslation as useOriginalTranslation } from 'react-i18next';
import useI18nCloned from './UseI18nCloned';

const useTranslation = (namespace, options) => {
    const i18nCloned = useI18nCloned();

    return useOriginalTranslation(namespace, { ...options, i18n: i18nCloned });
};

export default useTranslation;
