import React from 'react';
import { WysiwygViewer } from '@aseel/common-components';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { ButtonBase, Card, Divider } from '@mui/material';

export default function DueDiligenceTab({ text, files }) {
    return (
        <Card>
            {files.length > 0 ? (
                <div className="grid gap-4 lg:grid-cols-3">
                    {files.map((file) => (
                        <div>
                            <ButtonBase
                                component="a"
                                href={file.url}
                                target="_blank"
                                className="items-center justify-start px-2 py-3">
                                <GetAppRoundedIcon className="me-3" />
                                {file.name}
                            </ButtonBase>
                        </div>
                    ))}
                </div>
            ) : null}

            {files.length > 0 && text ? <Divider /> : null}
            {text ? (
                <div className="p-4">
                    <WysiwygViewer text={text} />
                </div>
            ) : null}
        </Card>
    );
}
