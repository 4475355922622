import React from 'react';
import { Card, CardContent, Chip, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

const ListItem = () => {
    return (
        <div className="flex py-3">
            <Typography className="flex-1 me-3" variant="body1" color="textSecondary">
                <Skeleton className="w-full" />
            </Typography>
            <Typography variant="body1" className="font-medium w-10">
                <Skeleton />
            </Typography>
        </div>
    );
};

const FundCardSkeleton = ({ data }) => {
    return (
        <Card>
            <div className="h-72 bg-cover bg-no-repeat relative flex items-end">
                <div className="bg-black bg-opacity-10 h-full w-full absolute z-10"></div>
                <Typography className="relative z-20 pb-2 ps-2 text-white w-full" variant="h5">
                    <Skeleton className="w-1/2" />
                    <Skeleton className="w-3/4" />
                </Typography>
            </div>
            <CardContent>
                <Chip size="small" label={<Skeleton className="w-10" />} />

                <div className="divide-y divide-gray-300">
                    <ListItem />
                    <ListItem />
                    <ListItem />
                    <ListItem />
                </div>
            </CardContent>
        </Card>
    );
};

export default FundCardSkeleton;
