import React from 'react';
import { http } from 'Utils/Http/Http';
import FundDiscussions from '@aseel/common-components/src/Funds/Discussions/FundDiscussions';
import ReplyForm from './Components/ReplyForm';

export default function FundDiscussionPage() {
    const discussionsRequest = ({ page, fundId }) =>
        http.get(`listings/${fundId}/discussions`, { params: { page } }).then((data) => data.data);

    const deleteReplyRequest = ({ fundId, discussionId, replyId }) => {
        return http.delete(`/listings/${fundId}/discussions/${discussionId}/replies/${replyId}`);
    };

    const deleteDiscussionRequest = ({ fundId, discussionId }) => {
        return http.delete(`/listings/${fundId}/discussions/${discussionId}`);
    };

    return (
        <FundDiscussions
            discussionsRequest={discussionsRequest}
            ReplyFormComponent={ReplyForm}
            deleteReplyRequest={deleteReplyRequest}
            deleteDiscussionRequest={deleteDiscussionRequest}
        />
    );
}
