import React from 'react';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, TableSkeleton } from '@aseel/common-ui';

export default function InvestorsTab({ ibanInvestorQuery }) {
    const { t } = useTranslation();

    const columns = [
        {
            field: 'full_name',
            headerName: t('funds.iban.name'),
            minWidth: 300,
            editable: false,
            flex: 1,
        },
        {
            field: 'invested_amount',
            headerName: t('funds.iban.inv_amt'),
            minWidth: 300,
            editable: false,
            renderCell: (row) => {
                return <p> SAR {row.row.invested_amount}</p>;
            },
        },
    ];

    return ibanInvestorQuery.isLoading ? (
        <div className="mt-5">
            <Card>
                <TableSkeleton />
            </Card>
        </div>
    ) : (
        <Card className="mt-5">
            <DataGrid pagination={false} rows={ibanInvestorQuery.data?.data} columns={columns}  />
        </Card>
    );
}
