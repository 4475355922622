import React from 'react';
import { Box } from '@mui/system';
import { List } from '@mui/material';
import useAuth from 'Hooks/UseAuth';
import Drawer from '@mui/material/Drawer';
import { NavLinksComponent } from './Header';
import { IconBtnBase } from '@aseel/common-ui';
import LogoutButton from '@aseel/common-components/src/Auth/LogoutButton';
import ProjectLogoDark from '@aseel/common-assets/assets/aseel-logo-dark.png';


const styles = {
    list: {
        width: 250,
    },
};

const DrawerList = ({ onToggleDrawer, auth }) => (
    <Box sx={styles.list} role="presentation" onClick={onToggleDrawer} onKeyDown={onToggleDrawer}>
        <List className="space-y-5 divide-y divide-gray-300">
            <div className="flex items-center flex-shrink-0 ml-4">
                <a href={process.env.REACT_APP_HOME_URL}>
                    <img className="block w-auto h-14 mt-1.5" src={ProjectLogoDark} alt="Aseel" />
                </a>
            </div>
            <div className="flex flex-col py-3.5 space-y-3 px-3.5">
                <NavLinksComponent auth={auth} hasButtonBase={false} />
                <LogoutButton auth={auth} isButton={false} />
            </div>
        </List>
    </Box>
);

export default function SidebarForMobile({ children }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const auth = useAuth();

    const handleToggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsOpen(!isOpen);
    };

    return (
        <div>
            <IconBtnBase onClick={handleToggleDrawer}>{children}</IconBtnBase>
            <Drawer anchor="left" open={isOpen} onClose={handleToggleDrawer}>
                <DrawerList onToggleDrawer={handleToggleDrawer} auth={auth} />
            </Drawer>
        </div>
    );
}
