import { useQuery } from 'react-query';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import { i18n } from '@aseel/common-utils';
import { useMediaQuery } from '@mui/material';
import { RoundedTabs } from '@aseel/common-ui';
import InvestorsTab from './Components/InvestorsTab';
import SpvAndEscrowTab from './Components/SpvAndEscrowTab';
import { FUND_IBAN_INVESTORS, FUND_IBAN_WALLETS } from 'Constants/QueriesKeys';
import FundIBanAndInvestorsSkeletons from './Skeletons/FundIBanAndInvestorsSkeletons';

export const tabsValues = {
    spv: 'SPV',
    escrow: 'ESCROW',
    investors: 'INVESTORS',
};

const tabs = () => [
    {
        label: i18n.t('funds.iban.spvTab'),
        value: tabsValues.spv,
    },
    {
        label: i18n.t('funds.iban.escrowTab'),
        value: tabsValues.escrow,
    },
    {
        label: i18n.t('funds.iban.investorsTab'),
        value: tabsValues.investors,
    },
];

export const walletTypesByTab = {
    [tabsValues.spv]: ['SPV'],
    [tabsValues.escrow]: ['ESCROW', 'ESCROW_VAT', 'ESCROW_ADMIN_FEE'],
};

export default function FundIBanAndInvestorsComponent({ ibanInvestorsRequest, ibanWalletRequest, fundId, scope }) {
    const [currentTab, setCurrentTab] = useState(tabsValues.spv);
    const { breakpoints } = useTheme();

    const isBreakpointGreaterThanXl = useMediaQuery(breakpoints.up('xl'));
    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const ibanInvestorQuery = useQuery([FUND_IBAN_INVESTORS, { id: fundId }], ibanInvestorsRequest);

    const ibanWalletQuery = useQuery([FUND_IBAN_WALLETS, { id: fundId }], ibanWalletRequest, {
        enabled: Boolean(fundId),
    });

    return (
        <div className="px-2.5">
            <RoundedTabs
                tabs={tabs()}
                value={currentTab}
                allowScrollButtonsMobile
                onChange={handleChangeTab}
                centered={isBreakpointGreaterThanXl}
                variant={isBreakpointGreaterThanXl ? 'standard' : 'scrollable'}
            />
            {ibanWalletQuery.isLoading ? (
                <FundIBanAndInvestorsSkeletons currentTab={currentTab} />
            ) : currentTab === tabsValues.spv || currentTab === tabsValues.escrow ? (
                <SpvAndEscrowTab
                    fundId={fundId}
                    currentTab={currentTab}
                    wallets={ibanWalletQuery.data?.data}
                    scope={scope}
                />
            ) : (
                <InvestorsTab ibanInvestorQuery={ibanInvestorQuery} />
            )}
        </div>
    );
}
