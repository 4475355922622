import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { IconBtn, InputController } from '@aseel/common-ui';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { IconButton, Tooltip } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

export default function MemberAddEditForm({ teamIndex, control }) {
    const { t } = useTranslation();

    const { fields, remove, append } = useFieldArray({
        control,
        name: `team[${teamIndex}].members`,
    });

    return (
        <>
            <div className="flex justify-center pt-5">
                <Tooltip title={t('funds.newMember')} placement="top">
                    <IconButton onClick={() => append({})} size="large">
                        <AddCircleOutlineRoundedIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 py-5">
                {fields.map(({ id }, index) => {
                    return (
                        <div key={id}>
                            <div className="flex py-3.5 lg:pr-2.5 pr-0">
                                <div className="px-1.5 space-y-1.5">
                                    <Avatar>
                                        <PersonRoundedIcon />
                                    </Avatar>
                                    <IconBtn onClick={() => remove(index)}>
                                        <DeleteForeverRoundedIcon fontSize="small" />
                                    </IconBtn>
                                </div>
                                <div className="space-y-3.5">
                                    <InputController
                                        control={control}
                                        label={t('funds.name')}
                                        name={`team[${teamIndex}].members[${index}].name`}
                                    />
                                    <InputController
                                        control={control}
                                        label={t('funds.jobTitle_en')}
                                        name={`team[${teamIndex}].members[${index}].job_title_en`}
                                    />
                                    <InputController
                                        control={control}
                                        label={t('funds.jobTitle_ar')}
                                        name={`team[${teamIndex}].members[${index}].job_title_ar`}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
