import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { Card, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { approvalStatusModalTypes } from '@aseel/common-components';
import { PAGE_BANK_ACCOUNTS } from 'Constants/QueriesKeys';
import { ApprovalStatusChip } from '@aseel/common-components';
import { BtnBase, DataGrid, ResourceListSkeleton } from '@aseel/common-ui';
import CreateBankAccountDialog from './Components/CreateBankAccountDialog';

function BankAccounts() {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const bankAccountsQuery = useQuery([PAGE_BANK_ACCOUNTS, { page }], () =>
        http
            .get('/bank-accounts', {
                params: {
                    page: page + 1,
                },
            })
            .then((response) => response.data),
    );

    if (bankAccountsQuery.isLoading) {
        return <ResourceListSkeleton />;
    }

    const handleOpenCreateBankAccountDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseCreateBankAccountDialog = () => {
        setIsDialogOpen(false);
    };

    const bankColumns = [
        {
            field: 'bank',
            headerName: t('bankAcc.bankName'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'name',
            headerName: t('bankAcc.name'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'iban',
            headerName: t('bankAcc.iban'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'review_status',
            headerName: t('common.status'),
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => (
                <ApprovalStatusChip
                    label={row.status.description}
                    status={row.status.value}
                    modalType={approvalStatusModalTypes.general}
                />
            ),
        },
    ];

    return (
        <div className="px-2.5 sm:px-0">
            <CreateBankAccountDialog
                isOpen={isDialogOpen}
                onClose={handleCloseCreateBankAccountDialog}
                bankAccountsQuery={bankAccountsQuery}
            />

            <div className="flex justify-between items-center pb-4">
                <Typography variant="h4">{t('bankAcc.bankAccounts')}</Typography>
                <BtnBase fullWidth={false} onClick={handleOpenCreateBankAccountDialog}>
                    {t('bankAcc.addAccount')}
                </BtnBase>
            </div>
            <Divider />
            <Card className="mb-20 mt-4">
                <DataGrid
                    columns={bankColumns}
                    rows={bankAccountsQuery.data?.data || []}
                    page={page}
                    pageSize={bankAccountsQuery.data?.meta.per_page}
                    rowCount={bankAccountsQuery.data?.meta.total}
                    onPageChange={(newPage) => setPage(newPage)}
                    paginationMode="server"
                />
            </Card>
        </div>
    );
}

export default BankAccounts;
