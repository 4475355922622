import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { handleError } from '@aseel/http';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { BANKS } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { InputController, Btn, setProgress, SelectController, UploaderController } from '@aseel/common-ui';

const CREATE_BTN = 'CREATE_BTN';

const schema = Yup.object().shape({
    name: Yup.string().required(),
    bank: Yup.string().required(),
    iban: Yup.string().required(),
    attachments: Yup.array().length(1, 'bankProofDocument').required(),
});

function CreateBankAccountDialog({ isOpen, onClose, bankAccountsQuery }) {
    const [isUploading, setIsUploading] = useState(false);
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            attachments: [],
        },
    });

    const banksQuery = useQuery(BANKS, () =>
        http
            .get('api/banks', {
                baseURL: process.env.REACT_APP_BASE_URL,
            })
            .then(({ data }) => data.data),
    );

    const handleFormSubmission = async (values) => {
        const form = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === 'attachments') {
                form.append(`attachments`, values[key][0]);
            } else {
                form.append(key, values[key]);
            }
        });
        form.append('_method', 'post');

        setProgress(CREATE_BTN, true);
        setIsUploading(true);
        try {
            await http.post(`bank-accounts`, form);
            bankAccountsQuery.refetch();
            onClose();
            reset();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CREATE_BTN, false);
        setIsUploading(false);
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={onClose}>
            <DialogContent>
                <form onSubmit={handleSubmit(handleFormSubmission)}>
                    <div className="mb-5">
                        <Typography variant="h5">{t('bankAcc.addAccount')}</Typography>
                    </div>
                    <div className="space-y-4">
                        <InputController control={control} name="name" label={t('bankAcc.name')} />

                        <SelectController
                            control={control}
                            name="bank"
                            label={t('bankAcc.bankName')}
                            options={banksQuery.data}
                            getOptionValue={(o) => o.code}
                            getOptionLabel={(o) => o.name}
                            menuPortalTarget={document.body}
                        />

                        <InputController control={control} name="iban" label={t('bankAcc.iban')} />

                        <UploaderController
                            name="attachments"
                            control={control}
                            label={t('bankAcc.proofDocuments')}
                            disabled={isUploading}
                            single
                        />
                    </div>
                    <div className="flex justify-end py-3">
                        <Btn type="submit" name={CREATE_BTN} fullWidth={false}>
                            {t('common.create')}
                        </Btn>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default CreateBankAccountDialog;
