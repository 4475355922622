import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { Scope } from '@aseel/constants';
import { url } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FUND_IBAN_TRANSACTIONS } from 'Constants/QueriesKeys';
import WalletTransactionsTable from './WalletTransactionsTable';
import { walletTypesByTab } from '../FundIBanAndInvestorsComponent';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Card, Divider, Typography, Box, IconButton } from '@mui/material';
import ExportReportsDialog from '../../../ExportReportDialog/ExportReportDialog';
import FundIBanAndInvestorsSkeletons from '../Skeletons/FundIBanAndInvestorsSkeletons';

export default function SpvAndEscrowTab({ wallets, fundId, currentTab, scope }) {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [isExportReportsDialogOpen, setIsExportReportsDialogOpen] = useState(false);

    const filteredWallets = wallets.filter((item) => walletTypesByTab[currentTab].includes(item.type));

    const ibanTransactionQuery = useQuery([FUND_IBAN_TRANSACTIONS, { id: fundId, currentTab, page }], () =>
        http
            .get(`/listings/${fundId}/transactions`, {
                params: {
                    type: currentTab,
                    page: page + 1,
                },
            })
            .then(({ data }) => data),
    );

    useEffect(() => {
        setPage(0);
    }, [currentTab]);

    if (ibanTransactionQuery.isLoading) {
        return <FundIBanAndInvestorsSkeletons />;
    }

    const reportDownloadUrl = (formData) =>
        url(`api/admin/listings/${fundId}/transactions/export`, { ...formData, wallet_type: currentTab });

    return (
        <div className="space-y-5">
            <ExportReportsDialog
                urlGenerator={reportDownloadUrl}
                isOpen={isExportReportsDialogOpen}
                isExportReportsDialogOpen={isExportReportsDialogOpen}
                setIsExportReportsDialogOpen={setIsExportReportsDialogOpen}
                onClose={() => setIsExportReportsDialogOpen(!isExportReportsDialogOpen)}
            />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        lg: `repeat(${filteredWallets.length > 3 ? 3 : filteredWallets.length}, 1fr)`,
                    },
                    gap: {
                        xs: 1,
                        sm: 5,
                    },
                }}>
                {filteredWallets?.map((item) => (
                    <Card className="mt-5">
                        <div className="py-5 text-center">
                            <Typography variant="body1" className="font-semibold">
                                {item.balance}
                            </Typography>
                            <Typography variant="body1" className="text-gray-600">
                                {item.name}
                            </Typography>
                        </div>
                        <Divider />
                        {item.virtual_accounts?.map((item) => (
                            <div className="py-5 text-center">
                                <Typography variant="body1" className="font-semibold">
                                    {item.identifier}
                                </Typography>
                                <Typography variant="body1" className="text-gray-600">
                                    {item.type.description}
                                </Typography>
                            </div>
                        ))}
                    </Card>
                ))}
            </Box>
            <div>
                <div>
                    {scope === Scope.admin ? (
                        <div className="flex items-center justify-between">
                            <Typography variant="subtitle1" color="textSecondary">
                                {t('funds.iban.transactions')}
                            </Typography>
                            <IconButton onClick={() => setIsExportReportsDialogOpen(!isExportReportsDialogOpen)}>
                                <DownloadRoundedIcon />
                            </IconButton>
                        </div>
                    ) : null}

                    <Card>
                        <WalletTransactionsTable
                            transactionQuery={ibanTransactionQuery}
                            page={page}
                            setPage={setPage}
                        />
                    </Card>
                </div>
            </div>
        </div>
    );
}
