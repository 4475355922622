import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { footerSections } from './Constants/FooterSections';
import { Container, Divider, Link, Typography } from '@mui/material';
import ProjectLogoDark from '@aseel/common-assets/assets/aseel-logo-dark.png';

const styles = {
    footerFirstSection: {
        backgroundColor: 'background.paper',
        padding: theme => `${theme.spacing(12)} ${theme.spacing(3)}`,
    },
    footerSecondSection: {
        backgroundColor: 'primary.main',
        padding: theme => `${theme.spacing(6)} ${theme.spacing(3)}`,
        color: 'primary.contrastText',
    },
};

export default function Footer() {
    const { t } = useTranslation();

    return (
        <div>
            <Divider />
            <Box sx={styles.footerFirstSection}>
                <Container maxWidth="xl">
                    <div className="grid gap-7 md:gap-4 md:grid-cols-3">
                        <div className="self-center">
                            <img src={ProjectLogoDark} className="w-auto h-20 mb-4" alt="Aseel Logo" />
                            <p className="text-gray-800">Invest in real estate in the world's best cities.</p>
                        </div>

                        {footerSections().map((section, index) => (
                            <div key={index}>
                                <Typography variant="h6" component="h1" className="mb-4">
                                    {section.title}
                                </Typography>
                                <ul>
                                    {section.links.map((link, linkIndex) => (
                                        <li key={linkIndex} className="py-1">
                                            <Link href={link.href} variant="body1" underline="none">
                                                {link.text}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </Container>
            </Box>

            <Box sx={styles.footerSecondSection}>
                <Container maxWidth="xl">
                    <div className="flex justify-between">
                        <div className="text-gray-300">{t('footer.madeInKsa')}</div>

                        <div className=""></div>

                        <div></div>
                    </div>
                </Container>
            </Box>
        </div>
    );
}
