import React from 'react';
import FundReportsComponent from '@aseel/common-components/src/Funds/Reports/FundReportsComponent';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router';

export default function FundReportsPage() {
    const { fundId } = useParams();
    const reportsRequest = ({ fundId, page }) => {
        return http
            .get(`/listings/${fundId}/reports`, {
                useParams: {
                    page: page + 1,
                },
            })
            .then((response) => response.data);
    };
    return (
        <div>
            <FundReportsComponent reportsRequest={reportsRequest} fundId={fundId} />
        </div>
    );
}
