import React from 'react';
import { http } from 'Utils/Http/Http';
import { NavLink, useParams } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FundRevisions from '@aseel/common-components/src/Funds/Revisions/FundRevisions';
import { Box } from '@mui/system';
import { Scope } from '@aseel/constants';

const styles = {
    rootContainer: {
        padding: (theme) => ({
            xs: `0 ${theme.spacing(3)}`,
            xl: 0,
        }),
        height: {
            xl: '85vh',
        },
    },
};

function FundRevisionsPage() {
    const { fundId } = useParams();
    const { t } = useTranslation();

    const revisionsRequest = () => http.get(`/listings/${fundId}/revisions`).then((response) => response.data.data);
    const fundRequest = () => http.get(`/listings/${fundId}`).then((response) => response.data.data);

    return (
        <Box sx={styles.rootContainer}>
            <Breadcrumbs className="mb-5">
                <Link component={NavLink} to={`/app/funds`}>
                    {t('funds.funds')}
                </Link>
                <Link component={NavLink} to={`/app/funds`}>
                    #{fundId}
                </Link>
                <Typography color="textPrimary"> {t('funds.fundRevisions')}</Typography>
            </Breadcrumbs>

            <FundRevisions
                getRevisionsRequest={revisionsRequest}
                getFundRequest={fundRequest}
                scope={Scope.authorizedEntity}
            />
        </Box>
    );
}

export default FundRevisionsPage;
