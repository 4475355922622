import React from 'react';
import useAuth from 'Hooks/UseAuth';
import { Link } from '@mui/material';
import { Popover } from '@headlessui/react';
import { BtnBase } from '@aseel/common-ui';
import { NavLink } from 'react-router-dom';
import { NavLinks } from './Constants/NavLinks';
import SidebarForMobile from './SidebarForMobile';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ProjectLogoDark from '@aseel/common-assets/assets/aseel-logo-dark.png';
import LogoutButton from '@aseel/common-components/src/Auth/LogoutButton';

export const getNavProps = (navItem, auth) => {
    if (!navItem.isVisible(auth)) {
        return null;
    }

    const props = {};

    if (navItem.type === 'route') {
        props.component = NavLink;
        props.to = navItem.path;
    } else if (navItem.type === 'url') {
        props.component = 'a';
        props.href = navItem.link;
    }

    return props;
};

export const NavLinksComponent = ({ hasButtonBase = true, auth }) => {
    return NavLinks().map((l, key) => {
        if (!l.isVisible(auth)) {
            return null;
        }

        const props = getNavProps(l, auth);

        return hasButtonBase ? (
            <BtnBase key={key} fullWidth={false} variant="text" {...props}>
                {l.text}
            </BtnBase>
        ) : (
            <Link key={key} className="font-semibold" style={{ textDecoration: 'none' }} {...props}>
                {l.text}
            </Link>
        );
    });
};

export default function Header() {
    const auth = useAuth();
    return (
        <div>
            <Popover>
                <nav
                    className="relative flex items-center justify-between px-6 py-2 mx-auto max-w-7xl xl:px-8"
                    aria-label="Global">
                    <div className="flex items-center flex-1">
                        <div className="flex items-center justify-between w-full pt-2 lg:w-auto">
                            <a href={process.env.REACT_APP_HOME_URL}>
                                <img className="w-auto h-14 sm:h-16" src={ProjectLogoDark} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="hidden lg:flex lg:items-center lg:space-x-6">
                        <NavLinksComponent auth={auth} />
                        <LogoutButton auth={auth} isButton />
                    </div>

                    <div className="lg:hidden">
                        <SidebarForMobile>
                            <MenuRoundedIcon />
                        </SidebarForMobile>
                    </div>
                </nav>
            </Popover>
        </div>
    );
}
